import dateformat from 'dateformat';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Const from '../Const';
import { useGetPlaceFacePicturesQuery } from '../generated/graphql';
import { Loading } from '../Loading';
import './PlaceDxfPicturesPage.css';
import path from 'path';

type PlaceDxfPicturesPage = RouteComponentProps<{
  placeId: string;
  placeFaceId: string;
}>;

export const PlaceDxfPicturesPage: React.FC<PlaceDxfPicturesPage> = function (
  props
) {
  const placeFaceId = parseInt(props.match.params.placeFaceId);

  const getPictures = useGetPlaceFacePicturesQuery({
    variables: { placeFaceId },
  });

  if (getPictures.loading || !getPictures.data) {
    return <Loading />;
  }

  function withExtension(url: string): string {
    if (path.extname(url) === '') {
      url += '.jpg?noext=true&download';
    } else {
      url += '?download';
    }
    return Const.API_URL + url;
  }

  const data = getPictures.data;
  const pictureIdToMarkerMap: { [key: number]: { index: number, deleted: boolean } } = {};
  for (const marker of data.getPlaceFace.markers) {
    pictureIdToMarkerMap[marker.picture_id] = marker;
  }

  const markerPictures = [];
  const noMarkerPictures = [];
  const deletedPictures = [];

  for (const picture of data.getPlaceFace.pictures) {
    const marker = pictureIdToMarkerMap[picture.id];
    if (marker) {
      if (marker.deleted) {
        deletedPictures.push(picture);
      } else {
        markerPictures.push(picture);
      }
    } else {
      noMarkerPictures.push(picture);
    }
  }

  const pictures = [
    ...markerPictures.sort((a, b) => {
      const m1 = pictureIdToMarkerMap[a.id];
      const m2 = pictureIdToMarkerMap[b.id];
      return m1.index - m2.index;
    }),
    ...noMarkerPictures,
    ...deletedPictures,
  ];

  return (
    <div className="PlaceDxfListPage-container">
      <table className="App-table">
        <thead>
          <tr>
            <th>番号</th>
            <th>名前</th>
            <th>画像</th>
            <th>日付</th>
          </tr>
        </thead>
        <tbody>
          {pictures.map((picture) => {
            return (
              <tr>
                <td>{(function () {
                  const marker = pictureIdToMarkerMap[picture.id];
                  if (!marker) {
                    return '-';
                  }
                  if (marker.deleted) {
                    return '削除済み';
                  }
                  return marker.index;
                })()}</td>
                <td>{picture.name}</td>
                <td>
                  <a href={withExtension(picture.full_url)}>
                    <img
                      className="PlaceDxfPicturesPage-thumbnail"
                      src={Const.API_URL + picture.thumbnail_url}
                      alt={picture.thumbnail_url}
                    />
                  </a>
                </td>
                <td>{dateformat(picture.created, 'yyyy年m月d日 HH:MM:ss')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
