import dateformat from 'dateformat';
import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  useClonePlaceFaceMutation,
  useDeletePlaceFaceMutation,
  useGetPlaceFacesLazyQuery,
} from '../generated/graphql';
import * as Const from '../Const';
import { Loading } from '../Loading';
import './PlaceDxfListPage.css';
import { AuthButton } from '../components/Button';
import { GraphQLErrorDialog, ConfirmDialog } from '../components/Dialogs';

type IPlaceDxfListPageProps = RouteComponentProps<{ placeId: string }>;

function getByteLengthString(bytes: number) {
  if (bytes === 0) return '0B';

  const k = 1000; // not 1024 (https://physics.nist.gov/cuu/Units/binary.html)
  const dm = 2;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

interface DeleteFaceTarget {
  id: number;
  dxf_file_name: string;
}

export const PlaceDxfListPage: React.FC<IPlaceDxfListPageProps> = function (
  props
) {
  const placeId = parseInt(props.match.params.placeId);
  const history = useHistory();
  const [previewFaceIndex, setPreviewFace] = React.useState<number>();
  const [
    deleteFaceTarget,
    setDeleteFaceTarget,
  ] = React.useState<DeleteFaceTarget>();
  const [cloneFace, cloneFaceResult] = useClonePlaceFaceMutation({
    onCompleted() {
      getFaces();
    },
  });
  const [deleteFace, deleteFaceResult] = useDeletePlaceFaceMutation({
    onCompleted() {
      getFaces();
    },
  });
  const [getFaces, getPlaceResult] = useGetPlaceFacesLazyQuery({
    variables: { placeId },
  });

  if (!getPlaceResult.called) {
    getFaces();
  }

  if (getPlaceResult.error) {
    return <GraphQLErrorDialog error={getPlaceResult.error} />;
  }

  if (getPlaceResult.error) {
    return <GraphQLErrorDialog error={getPlaceResult.error} />;
  }

  if (getPlaceResult.loading || !getPlaceResult.data) {
    return <Loading />;
  }

  return (
    <div className="PlaceDxfListPage-container">
      <Loading open={deleteFaceResult.loading || cloneFaceResult.loading} />
      {(function () {
        if (!deleteFaceTarget) {
          return;
        }

        return (
          <ConfirmDialog
            open={true}
            title="図枠削除"
            message={<p>{deleteFaceTarget.dxf_file_name}を削除しますか？</p>}
            onYes={() => {
              deleteFace({
                variables: { faceId: deleteFaceTarget.id },
              });
              setDeleteFaceTarget(undefined);
            }}
            onNo={() => setDeleteFaceTarget(undefined)}
          />
        );
      })()}
      {(function () {
        if (previewFaceIndex !== undefined) {
          const face = getPlaceResult.data.getPlace.faces[previewFaceIndex];
          return (
            <div className="PlaceDxfListPage-preview_container">
              <div className="PlaceDxfListPage-preview_background">
                <object
                  className="PlaceDxfListPage-prewview_svg"
                  data={`${Const.API_URL}${face.dxf_url}.svg`}
                  type="image/svg+xml"
                >
                  Dxf Preview
                </object>
                <button onClick={() => setPreviewFace(undefined)}>
                  閉じる
                </button>
              </div>
            </div>
          );
        } else {
          return <div></div>;
        }
      })()}
      <AuthButton
        authLv={2}
        onClick={() => history.push(`/places/${placeId}/faces/new`)}
      >
        新規作成
      </AuthButton>
      <table className="App-table">
        <thead>
          <tr>
            <th>ファイル名</th>
            <th>サイズ</th>
            <th>マーカー</th>
            <th>画像</th>
            <th>作成</th>
            <th>最終更新</th>
            <th colSpan={3}>操作</th>
          </tr>
        </thead>
        <tbody>
          {getPlaceResult.data.getPlace.faces.map((face, index) => {
            return (
              <tr>
                <td>
                  <a href={Const.API_URL + face.dxf_url + '?download=' + face.dxf_file_name}>
                    {face.dxf_file_name}
                  </a>
                </td>
                <td>{getByteLengthString(face.dxf_file_size)}</td>
                <td>
                  <Link to={`/places/${placeId}/faces/${face.id}/markers`}>
                    {face.markers.length}
                  </Link>
                </td>
                <td>
                  <Link to={`/places/${placeId}/faces/${face.id}/pictures`}>
                    {face.pictures.length}
                  </Link>
                </td>
                <td>{dateformat(face.created, 'yyyy年m月d日 HH:MM:ss')}</td>
                <td>{dateformat(face.updated, 'yyyy年m月d日 HH:MM:ss')}</td>
                <td className="App-table_button_cell">
                  <AuthButton
                    authLv={2}
                    mini={true}
                    color={'white'}
                    onClick={() =>
                      history.push(`/places/${placeId}/faces/${face.id}/edit`)
                    }
                  >
                    編集
                  </AuthButton>
                </td>
                <td className="App-table_button_cell">
                  <AuthButton
                    authLv={2}
                    mini={true}
                    color={'white'}
                    onClick={() =>
                      cloneFace({ variables: { placeFaceId: face.id } })
                    }
                  >
                    コピー
                  </AuthButton>
                </td>
                <td className="App-table_button_cell">
                  <AuthButton
                    authLv={2}
                    mini={true}
                    color="red"
                    onClick={() => setDeleteFaceTarget(face)}
                  >
                    削除
                  </AuthButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
