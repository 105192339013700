import React from 'react';
import {
  useGetDeviceCountLimitLazyQuery,
  useGetDevicesLazyQuery,
} from '../generated/graphql';
import { AppContext } from '../AppContext';
import { Loading } from '../Loading';
import { Link } from 'react-router-dom';
import { DeviceListHelper } from '../utils/DeviceListHelper';

export const DeviceStatusPage: React.FC = function () {
  const [getDevices, getDevicesResult] = useGetDevicesLazyQuery();
  const [
    getDeviceCountLimit,
    getDeviceCountLimitResult,
  ] = useGetDeviceCountLimitLazyQuery();

  const appContext = React.useContext(AppContext);
  const me = appContext.state.me;
  if (!me) {
    return <Loading />;
  }

  if (!getDevicesResult.called) {
    getDevices();
  }

  if (!getDeviceCountLimitResult.called) {
    getDeviceCountLimit();
  }

  const effectiveDays =
    getDeviceCountLimitResult.data?.getDeviceCountLimit.effective_days ?? 0;
  const quantity =
    getDeviceCountLimitResult.data?.getDeviceCountLimit.quantity ?? 0;
  const helper = new DeviceListHelper(
    effectiveDays,
    quantity,
    getDevicesResult.data?.getDevices
  );

  return (
    <div style={{ width: '100%' }}>
      <h2>台数使用状況</h2>
      <div>
        {helper.getUsingDeviceCount()}台使用中 (契約台数:{quantity}台)
        {(function () {
          if (me.authority.level < 99) {
            return;
          }
          return (
            <Link to="/superadmin" style={{ marginLeft: '2rem' }}>
              制限台数設定
            </Link>
          );
        })()}
      </div>
      <table className="App-table" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>最終ログイン日</th>
            <th>使用状況</th>
          </tr>
        </thead>
        <tbody>{helper.getElementArray()}</tbody>
      </table>
    </div>
  );
};
