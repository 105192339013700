import * as React from 'react';
import { useHistory } from 'react-router';
import {
  useUpdateShapeTileMutation,
  useGetShapeTileLazyQuery,
} from '../generated/graphql';
import { Loading } from '../Loading';
import { ToastContext } from '../components/Toast';
import { GraphQLErrorDialog } from '../components/Dialogs';
import { useValidation } from '../utils/Validation';
import { ValidationRules } from '../utils/ValidationRules';
import { UpdateButton } from '../components/Button';
import { Input } from '../components/Input';
import './AdminCreateAccountPage.css';

interface ConfigCreateShapeTilePageProps {
  existingId?: number;
}

export const ConfigCreateShapeTilePage: React.FC<ConfigCreateShapeTilePageProps> = function (
  props
) {
  const history = useHistory();
  const toastContext = React.useContext(ToastContext);
  const validation = useValidation(ValidationRules.Tile);
  const [name, setName] = validation.useState('name', '');
  const [width, setWidth] = validation.useState('width', 0);
  const [height, setHeight] = validation.useState('height', 0);
  const [margin, setMargin] = validation.useState('margin', 0);
  const [getShapeTile, getShapeTileResult] = useGetShapeTileLazyQuery({
    onCompleted(data) {
      const tile = data.getShapeTile;
      setName(tile.name);
      setWidth(tile.width);
      setHeight(tile.height);
      setMargin(tile.margin);
    },
  });
  const [updateShapeTile, updateShapeTileResult] = useUpdateShapeTileMutation({
    onCompleted() {
      toastContext.showToast('タイルを作成しました', 'success', 10000);
    },
  });

  if (updateShapeTileResult.called) {
    if (updateShapeTileResult.error) {
      return <GraphQLErrorDialog error={updateShapeTileResult.error} />;
    }

    if (updateShapeTileResult.loading || !updateShapeTileResult.data) {
      return <Loading />;
    }

    history.push('/configs');
  }

  if (props.existingId) {
    if (!getShapeTileResult.called) {
      getShapeTile({
        variables: {
          shapeTileId: props.existingId,
        },
      });
    }

    if (getShapeTileResult.error) {
      return <GraphQLErrorDialog error={getShapeTileResult.error} />;
    }

    if (getShapeTileResult.loading || !getShapeTileResult.data) {
      return <Loading />;
    }
  }

  return (
    <div className="AdminCreateAccountPage-container">
      <h1>タイル作成</h1>
      <div className="App-form PlaceCreatePage-form">
        <Input
          label="名称"
          className="AdminCreateAccountPage-name_input"
          type="string"
          onChange={(event) => {
            const name = event.target.value;
            setName(name);
          }}
          value={name}
          validationMessage={validation.get('name')}
        />
        <Input
          label="幅"
          className=""
          type="number"
          onChange={(event) => {
            setWidth(parseInt(event.target.value));
          }}
          value={width.toString()}
          validationMessage={validation.get('width')}
        />
        <Input
          label="高さ"
          className=""
          type="number"
          onChange={(event) => {
            setHeight(parseInt(event.target.value));
          }}
          value={height.toString()}
          validationMessage={validation.get('height')}
        />
        <Input
          label="間隔"
          className=""
          type="number"
          onChange={(event) => {
            setMargin(parseInt(event.target.value));
          }}
          value={margin.toString()}
          validationMessage={validation.get('margin')}
        />
        <div>
          <UpdateButton
            create={!props.existingId}
            disabled={validation.isInvalid}
            className="PlaceCreatePage-create_button"
            onClick={() => {
              updateShapeTile({
                variables: {
                  input: {
                    id: props.existingId,
                    name,
                    width,
                    height,
                    margin,
                  },
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
