import * as React from 'react';
import { useHistory } from 'react-router';
import {
  useUpdateAccountMyPasswordMutation,
  useUpdateAccountPasswordMutation,
} from '../generated/graphql';
import { AppContext } from '../AppContext';
import { ToastContext } from '../components/Toast';
import { Button } from '../components/Button';
import { PasswordInput } from '../components/PasswordInput';
import { GraphQLMutationErrorDialog } from '../components/Dialogs';
import { useValidation } from '../utils/Validation';
import { ValidationRules } from '../utils/ValidationRules';

interface CreateAccountPasswordPageProps {
  target: number | 'me';
  backPath: string;
}

export const AccountCreatePasswordPage: React.FC<CreateAccountPasswordPageProps> = function ({
  target,
  backPath,
}) {
  const history = useHistory();
  const toastContext = React.useContext(ToastContext);
  const validation = useValidation({
    password: ValidationRules.Account.password,
  });
  const appContext = React.useContext(AppContext);
  const [password, setPassword] = React.useState<string>('');
  const [
    updatePassword,
    updatePasswordResult,
  ] = useUpdateAccountPasswordMutation({
    onCompleted,
  });
  const [
    updateMyPassword,
    updateMyPasswordResult,
  ] = useUpdateAccountMyPasswordMutation({
    onCompleted,
  });

  function onCompleted() {
    toastContext.showToast('パスワードを設定しました', 'success', 3000);
    history.push(backPath);
  }

  for (const result of [updatePasswordResult, updateMyPasswordResult]) {
    if (!result.called) {
      continue;
    }

    if (result.error) {
      return <GraphQLMutationErrorDialog error={result.error} />;
    }
  }

  return (
    <div>
      <h2>パスワード設定</h2>
      <PasswordInput
        validation={validation}
        onChange={(password) => setPassword(password)}
      />
      <div>
        <Button
          onClick={() => {
            if (target === 'me') {
              if (!appContext.state.me) {
                return;
              }
              updateMyPassword({
                variables: {
                  input: { account_id: appContext.state.me.id, password },
                },
              });
            } else {
              updatePassword({
                variables: {
                  input: { account_id: target, password },
                },
              });
            }
          }}
        >
          設定
        </Button>
      </div>
    </div>
  );
};
