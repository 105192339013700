import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  useGetConfigQuery,
  useGetPlaceCodeLazyQuery,
  useUpdatePlaceCodeMutation,
} from '../generated/graphql';
import master from '../Master';
import { ToastContext } from '../components/Toast';
import { Loading } from '../Loading';
import { Input } from '../components/Input';
import { UpdateButton } from '../components/Button';
import { PlaceCodeShapeInput } from '../components/create-place-code/PlaceCodeShapeInput';
import {
  ICodeLayer,
  // PlaceCodeLayersInput,
} from '../components/create-place-code/PlaceCodeLayersInput';
import {
  IInputField,
  InputFieldsInput,
} from '../components/create-place-code/InputFieldsInput';
import {
  IAdditionalField,
  AdditoinalFieldsInput,
} from '../components/create-place-code/AdditoinalFieldsInput';
import {
  IOutputField,
  OutputFieldsInput,
} from '../components/create-place-code/OutputFieldsInput';
import {
  FieldType,
  IDrawingObject,
  DrawingObjectsInput,
} from '../components/create-place-code/DrawingObjectsInput';
import {
  GraphQLErrorDialog,
  GraphQLMutationErrorDialog,
  NotifyDialog,
} from '../components/Dialogs';
import './PlaceCreateCodePage.css';
import { useValidation } from '../utils/Validation';
import { ValidationRules } from '../utils/ValidationRules';

type IPlaceCreateCodePageProps = RouteComponentProps<{ placeId: string }> & {
  existingId?: number;
};

const allInputFields = master.InputFields.map((fieldMaster) => ({
  input_field_scheme_id: fieldMaster.id,
  unit: fieldMaster.defaultUnit,
  export: false,
  master: fieldMaster,
}));

export const PlaceCreateCodePage: React.FC<IPlaceCreateCodePageProps> = function (
  props
) {
  const placeId = parseInt(props.match.params.placeId);

  const history = useHistory();
  const toastContext = React.useContext(ToastContext);
  const validation = useValidation(ValidationRules.PlaceCode);

  const [name, setName] = validation.useState('name', '');
  const [work, setWork] = React.useState<number>();
  const [shape, setShape] = React.useState(1);
  const [shapeSymbol, setShapeSymbol] = React.useState(1);
  const [layers, setLayers] = React.useState<ICodeLayer[]>([]);
  const [inputFields, setInputFields] = validation.useState<IInputField[]>(
    'inputFields',
    allInputFields,
    true
  );
  const [additionalFields, setAdditionalFields] = validation.useState<
    IAdditionalField[]
  >('additionalFields', [], true);
  const [outputFields, setOutputFields] = validation.useState<IOutputField[]>(
    'outputFields',
    [],
    true
  );
  const [drawingObjects, setDrawingObjects] = validation.useState<
    IDrawingObject[]
  >('drawingObjects', [], true);

  const shapeMaster = master.Shapes.find((s) => s.id === shape);
  if (!shapeMaster) {
    throw new Error('invalid shape id');
  }

  const [hasReferenceError, setHasReferenceError] = React.useState(false);

  // 図形の種類に応じた入力項目
  const shapeInputFields = shapeMaster.input_field_ids
    .map((id) => inputFields.find((field) => field.input_field_scheme_id === id))
    .filter((field): field is IInputField => !!field);

  const getConfigResult = useGetConfigQuery();

  const [getPlaceCode, getPlaceCodeResult] = useGetPlaceCodeLazyQuery({
    onCompleted(data) {
      const code = data.getPlaceCode;
      setName(code.name);
      setWork(code.selected_work?.id);
      setShape(code.shape_scheme_id);
      setShapeSymbol(code.shape_symbol_scheme_id);
      setLayers(code.layers);

      setInputFields(
        inputFields.map((shapeInputField) => {
          const field = code.input_fields.find(
            (f) =>
              f.input_field_scheme_id === shapeInputField.input_field_scheme_id
          );

          if (!field) {
            return shapeInputField;
          }
          return {
            input_field_scheme_id: field.input_field_scheme_id,
            unit: field.unit,
            export: field.export_to_summary,
            master: shapeInputField.master,
          };
        })
      );
      setAdditionalFields(
        code.additional_fields.map((f) => ({
          id: f.id,
          name: f.name,
          unit: f.unit,
          valueType: f.value_type,
          options: f.options,
          export: f.export_to_summary,
        }))
      );
      setOutputFields(
        code.output_fields.map((f) => ({
          id: f.id,
          name: f.name,
          formula: f.formula,
          unit: f.unit,
          export: f.export_to_summary,
        }))
      );

      const drawingObjects = [];
      for (const obj of code.drawing_objects) {
        let fieldIndex;
        switch (obj.field_type) {
          case FieldType.InputField:
            const field = code.input_fields.find((f) => f.id === obj.field_id);
            if (!field) {
              setHasReferenceError(true);
              continue;
            }
            fieldIndex = field.input_field_scheme_id;
            break;
          case FieldType.AdditionalField:
            fieldIndex = code.additional_fields.findIndex(
              (f) => f.id === obj.field_id
            );
            break;
          case FieldType.OutputField:
            fieldIndex = code.output_fields.findIndex(
              (f) => f.id === obj.field_id
            );
            break;
          default:
            throw new Error('unknown field type');
        }

        drawingObjects.push({
          id: obj.id,
          fieldType: obj.field_type,
          fieldIndex,
          prefix: obj.prefix,
          suffix: obj.suffix,
          lineType: obj.leader_line_type,
          arrowEndType: obj.leader_arrow_end_type,
          digit: obj.digit,
        });
      }

      setDrawingObjects(drawingObjects);
    },
  });

  const [addPlaceCode, addPlaceCodeResult] = useUpdatePlaceCodeMutation({
    onCompleted() {
      const message = props.existingId
        ? '補修コードを修正しました'
        : '補修コードを作成しました';
      toastContext.showToast(message, 'success', 3000);

      history.push(`/places/${placeId}/codes`);
    },
  });

  function update() {
    const drawingObjectsData = [];
    for (const obj of drawingObjects) {
      let fieldIndex;
      if (obj.fieldType === FieldType.InputField) {
        fieldIndex = shapeInputFields.findIndex(
          (field) => field.master.id === obj.fieldIndex
        );
      } else {
        fieldIndex = obj.fieldIndex;
      }

      if (fieldIndex === -1) {
        console.warn('不正な参照を持つDrawingObjectを無視します');
        continue;
      }

      drawingObjectsData.push({
        id: obj.id,
        field_type: obj.fieldType,
        field_index: fieldIndex,
        prefix: obj.prefix,
        suffix: obj.suffix,
        leader_line_type: obj.lineType,
        leader_arrow_end_type: obj.arrowEndType,
        digit: obj.digit,
      });
    }

    addPlaceCode({
      variables: {
        input: {
          id: props.existingId,
          place_id: placeId,
          name: name,
          work_id: work,
          shape_scheme_id: shape,
          shape_symbol_scheme_id: shapeSymbol,
          layers: layers.map((layer) => ({
            name: layer.name,
          })),
          input_fields: shapeInputFields.map((field) => ({
            input_field_scheme_id: field.input_field_scheme_id,
            unit: field.unit,
            export: field.export,
          })),
          additional_fields: additionalFields.map((field) => ({
            id: field.id,
            name: field.name,
            unit: field.unit,
            export: field.export,
            value_type: field.valueType,
            options: field.options,
          })),
          output_fields: outputFields.map((field) => ({
            id: field.id,
            name: field.name,
            formula: field.formula,
            unit: field.unit,
            export: field.export,
          })),
          drawing_objects: drawingObjectsData,
        },
      },
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////

  // 編集の場合は既存のデータを取得する
  if (props.existingId && !getPlaceCodeResult.called) {
    getPlaceCode({
      variables: {
        placeId,
        placeCodeId: props.existingId
      },
    });
  }

  if (getConfigResult.error) {
    return <GraphQLErrorDialog error={getConfigResult.error} />;
  }

  if (getConfigResult.loading || !getConfigResult.data) {
    return <Loading />;
  }

  if (getPlaceCodeResult.called) {
    if (getPlaceCodeResult.error) {
      return <GraphQLErrorDialog error={getPlaceCodeResult.error} />;
    }

    if (getPlaceCodeResult.loading || !getPlaceCodeResult.data) {
      return <Loading />;
    }
  }

  if (addPlaceCodeResult.called) {
    if (addPlaceCodeResult.error) {
      return <GraphQLMutationErrorDialog error={addPlaceCodeResult.error} />;
    }

    if (addPlaceCodeResult.loading) {
      return <Loading />;
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="PlaceCreateCodePage-container">
      <NotifyDialog
        open={hasReferenceError}
        title="不正なデータ"
        message={<p>不正な参照を持つ図面上に作画する文字列があります（再度保存すると修正されます）。</p>}
        onYes={() => setHasReferenceError(false)}
        />
      <h2>{props.existingId ? '補修コードの変更' : '補修コードの新規作成'}</h2>
      <div className="PlaceCreateCodePage-form">
        <h3>基本情報</h3>
        <p>
          コード名は作業者にわかりやすい名称を入力してください。
          <br />
          （例: 「ひびわれ0.5mm」「タイル浮き」「白華現象」）
        </p>
        <Input
          label="コード名"
          value={name}
          validationMessage={validation.get('name')}
          onChange={(event) => setName(event.target.value)}
        />
        <PlaceCodeShapeInput
          drawingObjects={drawingObjects}
          tiles={getConfigResult.data.getConfig.tiles}
          shapeSchemeId={shape}
          shapeSymbolSchemeId={shapeSymbol}
          onChangeShape={setShape}
          onChangeShapeSymbol={setShapeSymbol}
        />
        <h3>施工管理</h3>
        <select
          value={work ?? 0}
          onChange={(event) => {
            const nextWorkId = parseInt(event.target.value);
            if (nextWorkId === 0) {
              setWork(undefined);
            } else {
              setWork(nextWorkId);
            }
          }}
        >
          <option key={0} value={0}>施工管理なし</option>
          {getPlaceCodeResult.data?.getPlace.works.map(work =>
            <option key={work.id} value={work.id}>{work.name}</option>)}
        </select>
        <h3>自動計測項目</h3>
        <p>
          「自動計測項目」は図形登録時に自動的に計測される項目です。
          <br />
          「集計表に出力」にチェックを入れると、数量表1ページ目の「集計表」に積算された結果が記載されます。
        </p>
        <InputFieldsInput
          value={shapeInputFields}
          validation={validation.get('inputFields')}
          onChange={(fields) =>
            setInputFields((inputFields) =>
              inputFields.map((field) => {
                let updated = fields.find(
                  (f) => f.input_field_scheme_id === field.input_field_scheme_id
                );
                return updated ?? field;
              })
            )
          }
        />

        <h3>手動入力項目</h3>
        <p>
          「手動入力項目」は図形登録時に作業者が手入力する必要のある項目です。
          <br />
          種別や特殊な情報などを追加で入力してほしい場合に使用します。
          <br />
          「集計表に出力」にチェックを入れると、数量表1ページ目の「集計表」に積算された結果が記載されます。
        </p>
        <AdditoinalFieldsInput
          value={additionalFields}
          drawingObjects={drawingObjects}
          validation={validation.get('additionalFields')}
          onChange={(fields) => setAdditionalFields(fields)}
        />

        <h3>計算結果項目</h3>
        <p>
          「計算結果項目」は、「自動計測項目」「手動入力項目」を使って計算した値が代入される項目です。
          <br />
          (例:「横の長さ * 1.2」「面積 / 120」)
          <br />
          「集計表に出力」にチェックを入れると、数量表1ページ目の「集計表」に積算された結果が記載されます。
        </p>
        <OutputFieldsInput
          value={outputFields}
          drawingObjects={drawingObjects}
          validation={validation.get('outputFields')}
          onChange={(fields) => setOutputFields(fields)}
        />

        <h3>図面上に作画する文字列</h3>
        <DrawingObjectsInput
          value={drawingObjects}
          validation={validation.get('drawingObjects')}
          inputFields={shapeInputFields}
          additionalFields={additionalFields}
          outputFields={outputFields}
          onChange={(objs) => setDrawingObjects(objs)}
        />

        <div>
          <UpdateButton
            create={!props.existingId}
            disabled={validation.isInvalid}
            onClick={update}
          />
        </div>
      </div>
    </div>
  );
};
