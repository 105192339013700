import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AppContext } from './AppContext';
import { Loading } from './Loading';
import './stylesheets/Navigator.css';

interface INavigatorProps {}

type INavigatorPropsWithRouter = RouteComponentProps<any> & INavigatorProps;

/**
 * アプリケーションのナビゲーション (メニュー)
 */
const Navigator: React.FC<INavigatorPropsWithRouter> = function (props) {
  const path = props.location.pathname;
  const appContext = React.useContext(AppContext);

  const me = appContext.state.me;

  if (!me) {
    return <Loading />;
  }

  return (
    <div className="Navigator-container">
      <nav>
        <ul>
          <Link to="/dashboard">
            <li
              className={
                path.startsWith('/dashboard') ? 'Navigator-selected' : ''
              }
            >
              <div className="Navigator-link_icon">
                <img alt="Home" src="/home.svg" />
              </div>
              <div className="Navigator-link_text">マイページ</div>
            </li>
          </Link>
          <Link to="/places">
            <li
              className={path.startsWith('/places') ? 'Navigator-selected' : ''}
            >
              <div className="Navigator-link_icon">
                <img alt="Places" src="/building.svg" />
              </div>
              <div className="Navigator-link_text">物件一覧</div>
            </li>
          </Link>
          {(function () {
            if (me.authority.level < 2) {
              return;
            }

            return (
              <Link to="/configs">
                <li
                  className={
                    path.startsWith('/configs') ? 'Navigator-selected' : ''
                  }
                >
                  <div className="Navigator-link_icon">
                    <img alt="Config" src="/config.svg" />
                  </div>
                  <div className="Navigator-link_text">共通設定</div>
                </li>
              </Link>
            );
          })()}
          {(function () {
            if (me.authority.level < 3) {
              return;
            }

            return (
              <Link to="/admin">
                <li
                  className={
                    path.startsWith('/admin') ? 'Navigator-selected' : ''
                  }
                >
                  <div className="Navigator-link_icon">
                    <img alt="Admin" src="/setting.svg" />
                  </div>
                  <div className="Navigator-link_text">管理画面</div>
                </li>
              </Link>
            );
          })()}
        </ul>
      </nav>
    </div>
  );
};

export const NavigatorWithRouter = withRouter((props) => (
  <Navigator {...props} />
));
