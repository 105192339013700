import * as React from 'react';
import './PlaceTag.css';

interface IPlaceTagProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  selected?: boolean;
  onClick?: () => void;
  onDeleteClick?: () => void;
}

export const PlaceTag: React.FC<IPlaceTagProps> = function ({ selected, onClick, onDeleteClick, ...buttonHTMLProps }) {
  let className = 'PlaceTag-button';
  if (selected) {
    className += ' selected';
  }
  return (
    <button {...buttonHTMLProps} onClick={onClick} className={className}>
      <div className='PlaceTag-contents'>
        <span className='PlaceTag-message'>{buttonHTMLProps.children}</span>
        {(function () {
          if (onDeleteClick) {
            return (
              <button onClick={(e) => {
                e.stopPropagation();
                onDeleteClick?.();
              }} className='PlaceTag-delete_button'>
                <img alt="Delete" className="PlaceTag-delete_icon" src="/delete.png" />
              </button>
            );
          }
        })()}
      </div>
    </button>
  );
};
