import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { useHistory } from 'react-router';
import Cookies from 'universal-cookie';
import { AppContext } from '../AppContext';
import { Button } from './Button';
import './Dialogs.css';

interface IDialogContainerProps {
  open: boolean;
  message: React.ReactElement<any, any>;
  title?: string;
}

const DialogContainer: React.FC<IDialogContainerProps> = function (props) {
  return (
    <div className="Dialog-background">
      <div className="Dialog">
        <div className="Dialog-main">
          <p className="Dialog-title">{props.title}</p>
          {props.message}
        </div>
        <div className="Dialog-button_container">{props.children}</div>
      </div>
    </div>
  );
};

interface INotifyDialogProps extends IDialogContainerProps {
  yesLabel?: string;
  onYes: () => void;
}

export const NotifyDialog: React.FC<INotifyDialogProps> = function (props) {
  if (!props.open) {
    return <div></div>;
  }

  return (
    <DialogContainer {...props}>
      <Button onClick={() => props.onYes?.()}>{props.yesLabel ?? 'OK'}</Button>
    </DialogContainer>
  );
};

interface IConfirmDialogProps extends IDialogContainerProps {
  yesLabel?: string;
  noLabel?: string;
  onYes: () => void;
  onNo?: () => void;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = function (props) {
  if (!props.open) {
    return <div></div>;
  }

  return (
    <DialogContainer {...props}>
      <Button onClick={() => props.onYes?.()}>
        {props.yesLabel ?? 'OK'}
      </Button>
      <Button
        color="white"
        onClick={() => props.onNo?.()}
      >
        {props.yesLabel ?? 'キャンセル'}
      </Button>
    </DialogContainer>
  );
};

interface IFatalErrorDialogProps {}

export const FatalErrorDialog: React.FC<IFatalErrorDialogProps> = function (
  props
) {
  return (
    <DialogContainer
      {...props}
      title="エラー"
      message={<p>エラーが発生しました</p>}
      open={true}
    >
      <Button onClick={() => window.location.reload()}>
        再読み込み
      </Button>
    </DialogContainer>
  );
};


interface IApiErrorDialogProps {
  error: Error;
}

export const ApiErrorDialog: React.FC<IApiErrorDialogProps> = function (
  props
) {
  return (
    <DialogContainer
      {...props}
      title="通信エラー"
      message={<p>{props.error.message}</p>}
      open={true}
    >
      <Button
        onClick={() => window.location.reload()}>
        再読み込み
      </Button>
    </DialogContainer>
  );
};

interface IGraphQLErrorDialogProps {
  error: ApolloError;
}

export const GraphQLErrorDialog: React.FC<IGraphQLErrorDialogProps> = function (
  props
) {
  return (
    <DialogContainer
      {...props}
      title="サーバーエラー"
      message={<p>{props.error.message}</p>}
      open={true}
    >
      <Button onClick={() => window.location.reload()}>
        再読み込み
      </Button>
    </DialogContainer>
  );
};

interface IGraphQLMutationErrorDialogProps {
  error: ApolloError;
  onOk?: () => void;
}

export const GraphQLMutationErrorDialog: React.FC<IGraphQLMutationErrorDialogProps> = function (
  props
) {
  const [open, setOpen] = React.useState(true);

  return (
    <DialogContainer
      title="サーバーエラー"
      message={<p>エラーが発生しました。時間をおいて再実行してください。</p>}
      open={open}
    >
      <Button
        onClick={() => {
          setOpen(false);
          props.onOk?.();
        }}
      >
        OK
      </Button>
    </DialogContainer>
  );
};
