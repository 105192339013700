import * as React from 'react';
import { Checkbox, DeletableInput, Input } from '../Input';
import { Button } from '../Button';
import { Validated } from '../Validated';
import { FieldType, IDrawingObject } from './DrawingObjectsInput';
import { NotifyDialog } from '../Dialogs';

export enum ValueType {
  Int = 1,
  Float,
  String,
  Option,
}

export interface IAdditionalField {
  readonly id?: number;
  name: string;
  unit: string;
  valueType: ValueType;
  options: string[];
  export: boolean;
}

function defaultAdditionalField(): IAdditionalField {
  return {
    name: '',
    valueType: ValueType.Int,
    export: false,
    options: [],
    unit: '',
  };
}

export interface AdditionalFieldsInputProps {
  value: IAdditionalField[];
  drawingObjects: IDrawingObject[];
  validation: string | undefined;
  onChange: (fields: IAdditionalField[]) => void;
}

export const AdditoinalFieldsInput: React.FC<AdditionalFieldsInputProps> = function (
  props
) {
  const [canceled, setCanceled] = React.useState(false);

  function changeValue(index: number, field: IAdditionalField) {
    const next = props.value.slice();
    next[index] = field;
    props.onChange(next);
  }
  function addValue() {
    props.onChange([...props.value, defaultAdditionalField()]);
  }
  function removeValue(index: number) {
    // DrawingObject
    const changable = props.drawingObjects.every((obj) => {
      if (obj.fieldType !== FieldType.AdditionalField) {
        return true;
      }

      if (obj.fieldIndex !== index) {
        return true;
      }

      return false;
    });

    if (!changable) {
      setCanceled(true);
      return;
    }

    props.onChange(props.value.filter((_, i) => i !== index));
  }

  function row(field: IAdditionalField, index: number) {
    return (
      <tr>
        <td>
          <Input
            value={field.name}
            onChange={(event) =>
              changeValue(index, { ...field, name: event.target.value })
            }
          />
        </td>
        <td>
          <select
            value={field.valueType}
            onChange={(event) => {
              let valueType: ValueType;
              switch (event.target.value) {
                case '1':
                  valueType = ValueType.Int;
                  break;
                case '2':
                  valueType = ValueType.Float;
                  break;
                case '4':
                  valueType = ValueType.Option;
                  break;
                case '3':
                default:
                  valueType = ValueType.String;
                  break;
              }

              changeValue(index, { ...field, valueType });
            }}
          >
            <option value="1">整数</option>
            <option value="2">小数</option>
            <option value="3">文字列</option>
            <option value="4">選択式</option>
          </select>
        </td>
        <td>
          {(function () {
            if (field.valueType !== ValueType.Option) {
              return;
            }

            return (
              <ul className="PlaceCreateCodePage-additional_field_options_list">
                {(function () {
                  return field.options.map((option, optionIndex) => (
                    <li>
                      <DeletableInput
                        value={option}
                        onChange={(event) => {
                          const v = event.target.value;
                          const options = field.options.slice();
                          options[optionIndex] = v;

                          changeValue(index, { ...field, options });
                        }}
                        onDelete={() => {
                          const options = field.options.filter(
                            (_, i) => i !== optionIndex
                          );
                          changeValue(index, { ...field, options });
                        }}
                      />
                    </li>
                  ));
                })()}
                <li>
                  <button
                    onClick={() => {
                      const options = [...field.options, ''];
                      changeValue(index, { ...field, options });
                    }}
                  >
                    追加
                  </button>
                </li>
              </ul>
            );
          })()}
        </td>
        <td>
          <Checkbox
            value={field.export}
            onChange={(value) =>
              changeValue(index, { ...field, export: value })
            }
          />
        </td>
        <td>
          <Input
            value={field.unit}
            onChange={(event) =>
              changeValue(index, { ...field, unit: event.target.value })
            }
          />
        </td>
        <td className="App-table_button_cell">
          <Button mini={true} color="red" onClick={() => removeValue(index)}>
            削除
          </Button>
        </td>
      </tr>
    );
  }

  return (
    <Validated message={props.validation}>
      {(function () {
        if (!canceled) {
          return;
        }

        return (
          <NotifyDialog
            open={true}
            message={<p>作画文字列に参照されているため削除できません</p>}
            onYes={() => {
              setCanceled(false);
            }}
          />
        );
      })()}
      <table className="App-table">
        <thead>
          <tr>
            <th>名称</th>
            <th colSpan={2}>値の種類</th>
            <th>集計表に出力</th>
            <th>単位</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.value.map(row)}
          <tr>
            <td colSpan={6}>
              <Button onClick={() => addValue()}>追加</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Validated>
  );
};
