import Cookies from 'universal-cookie';
import * as Const from '../Const';
import axios from 'axios';

type Fetch = {
  (input: RequestInfo, init?: RequestInit): Promise<Response>;
};

const ApiHeaders = {
  'content-type': 'application/json',
};

class HttpClient {
  private readonly baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  post<T>(
    entryPoint: string,
    body: T,
    contentType: string | undefined = undefined
  ) {
    const headers: { [key: string]: string } = { ...ApiHeaders };

    const cookies = new Cookies();
    const authToken = cookies.get<string>('auth-token');
    if (authToken) {
      headers['x-account-auth-token'] = authToken;
    }

    if (contentType) {
      headers['content-type'] = contentType;
    }

    return axios.post(this.baseUrl + entryPoint, body, {
      headers,
    });
  }
}

export class ApiClient {
  private readonly http: HttpClient;

  constructor() {
    this.http = new HttpClient(Const.API_URL);
  }

  async login(data: { screen_id: string; password: string }): Promise<void> {
    const response = await this.http.post(
      '/v1/auth/login',
      JSON.stringify(data)
    );
    const loginData = response.data.result;
    const expiredAt = new Date(loginData.expired);

    const cookies = new Cookies();
    cookies.set('auth-token', loginData.token, {
      expires: expiredAt,
      path: '/',
    });
  }

  async getAppinfo(): Promise<{ companyName: string }> {
    const response = await axios.get(Const.API_URL + '/appInfo');
    const appInfo = response.data;
    return {
      companyName: appInfo.company_name
    };
  }

  async upload(file: File): Promise<[string, number]> {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const res = await this.http.post(
      `/files/upload`,
      bodyFormData,
      'multipart/form-data'
    );
    return [res.data.fileName, res.data.fileSize];
  }
}
