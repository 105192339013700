import React from 'react';
import { Device } from '../generated/graphql';

export class DeviceListHelper {
  readonly effectiveDays: number;
  readonly quantity: number;
  readonly devices: Pick<Device, 'device_id' | 'last_login'>[] | undefined;

  constructor(
    effectiveDays: number,
    quantity: number,
    devices: Pick<Device, 'device_id' | 'last_login'>[] | undefined
  ) {
    this.effectiveDays = effectiveDays;
    this.quantity = quantity;
    this.devices = devices;
  }

  getDevices() {
    const usingThreshold =
      new Date().getTime() - this.effectiveDays * 24 * 60 * 60 * 1000;

    const devices = this.devices?.map((d, i) => {
      const date = new Date(d.last_login);
      return {
        date,
        deviceId: d.device_id,
      };
    });
    if (devices) {
      return devices
        .sort((a, b) => b.date.getTime() - a.date.getTime())
        .map((d, i) => {
          const isUsing =
            d.date.getTime() > usingThreshold && this.quantity > i;
          return {
            ...d,
            isUsing,
          };
        });
    }
    return [];
  }

  getUsingDeviceCount() {
    return this.getDevices().filter((_) => _.isUsing).length;
  }

  getElementArray() {
    return this.getDevices()
      .sort((a, b) => b.date.getTime() - a.date.getTime())
      .map((d, i) => {
        return (
          <tr key={i}>
            <td>{d.deviceId}</td>
            <td>{d.date.toLocaleString()}</td>
            <td>{d.isUsing ? '使用中' : ''}</td>
          </tr>
        );
      });
  }
}
