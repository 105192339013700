import * as React from 'react';
import { InputField as InputFieldMaster } from '../../Master';
import { Checkbox, Input } from '../Input';
import { Validated } from '../Validated';

export interface IInputField {
  input_field_scheme_id: number;
  unit: string;
  export: boolean;
  readonly master: InputFieldMaster;
}

interface InputFieldsInputProps {
  value: IInputField[];
  validation: string | undefined;
  onChange: (fields: IInputField[]) => void;
}

export const InputFieldsInput: React.FC<InputFieldsInputProps> = function (
  props
) {
  function changeValue(inputFieldSchemeId: number, field: IInputField) {
    const next = props.value.slice();
    const index = props.value.findIndex(
      (field) => field.input_field_scheme_id === inputFieldSchemeId
    );

    next[index] = field;
    props.onChange(next);
  }

  function row(field: IInputField) {
    return (
      <tr key={field.input_field_scheme_id}>
        <td>{field.master.name}</td>
        <td>
          <Checkbox
            value={field.export}
            onChange={(value) => {
              changeValue(field.input_field_scheme_id, {
                ...field,
                export: value,
              });
            }}
          />
        </td>
        <td>
          <Input
            value={field.unit}
            onChange={(event) => {
              changeValue(field.input_field_scheme_id, {
                ...field,
                unit: event.target.value,
              });
            }}
          />
        </td>
      </tr>
    );
  }

  if (props.value.length === 0) {
    return <p>入力項目はありません。</p>;
  }

  return (
    <Validated message={props.validation}>
      <table className="App-table">
        <thead>
          <tr>
            <th>名称</th>
            <th>集計表に出力</th>
            <th>単位</th>
          </tr>
        </thead>
        <tbody>{props.value.map(row)}</tbody>
      </table>
    </Validated>
  );
};
