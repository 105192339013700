import * as React from 'react';
import { useHistory } from 'react-router';
import { ApiClient } from '../utils/ApiClient';
import { NotifyDialog } from '../components/Dialogs';
import { Button } from '../components/Button';
import './LoginPage.css';

interface ILoginPageState {
  inputId: string;
  inputPassword: string;

  // ログイン中かどうか
  isLoggingIn: boolean;

  // ログインしたかどうか
  authorized: boolean;
}

interface ILoginPageProps {}

export const LoginPage: React.FC<ILoginPageProps> = function () {
  const history = useHistory();
  const [state, setState] = React.useState<ILoginPageState>({
    inputId: '',
    inputPassword: '',
    isLoggingIn: false,
    authorized: false,
  });
  const [loginError, setLoginError] = React.useState<Error>();

  async function login() {
    setState((prev) => ({ ...prev, isLoggingIn: true }));

    let authorized = false;
    try {
      const apiClient = new ApiClient();

      await apiClient.login({
        screen_id: state.inputId,
        password: state.inputPassword,
      });

      authorized = true;
    } catch (error) {
      setLoginError(error);
    } finally {
      setState((prev) => ({
        ...prev,
        authorized,
        isLoggingIn: false,
      }));
    }
  }

  if (state.authorized) {
    history.push('/');
  }

  return (
    <div className="LoginPage-login_container">
      <NotifyDialog
        open={!!loginError}
        message={<p>ログインに失敗しました</p>}
        onYes={() => setLoginError(undefined)}
      />
      <div className="LoginPage-title_container">
        <p className="App-title LoginPage-title">スマート ST</p>
      </div>
      <div className="LoginPage-id_container">
        <input
          className="LoginPage-login_id_input"
          type="string"
          placeholder="ログインID"
          onChange={(event) => {
            const inputId = event.target.value;
            setState((prev) => ({ ...prev, inputId }));
          }}
          value={state.inputId}
        />
      </div>
      <div className="LoginPage-password_container">
        <input
          className="LoginPage-login_password_input"
          type="password"
          placeholder="パスワード"
          onChange={(event) => {
            const inputPassword = event.target.value;
            setState((prev) => ({ ...prev, inputPassword }));
          }}
          value={state.inputPassword}
        />
      </div>
      <div className="LoginPage-login_button_container">
        <Button
          className="LoginPage-login_button"
          onClick={login}
          disabled={state.isLoggingIn}
        >
          {state.isLoggingIn ? 'ログイン中...' : 'ログイン'}
        </Button>
      </div>
    </div>
  );
};
