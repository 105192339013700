import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import './PlaceIndexPage.css';
import { useDeletePlaceMutation, useGetPlaceQuery } from '../generated/graphql';
import { Loading } from '../Loading';
import { formatDate } from '../utils/DateHelper';
import { AuthButton } from '../components/Button';
import {
  ConfirmDialog,
  GraphQLErrorDialog,
  GraphQLMutationErrorDialog,
} from '../components/Dialogs';
import { ToastContext } from '../components/Toast';
import { PlaceTag } from '../components/PlaceTag';

type IPlaceIndexPageProps = RouteComponentProps<{ placeId: string }>;

/**
 * 現場・基本情報ページ
 */
export const PlaceIndexPage: React.FC<IPlaceIndexPageProps> = function (props) {
  const history = useHistory();
  const toastContext = React.useContext(ToastContext);
  const placeId = parseInt(props.match.params.placeId);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const { data, loading, error } = useGetPlaceQuery({
    variables: { placeId },
  });
  const [deletePlace, deletePlaceResult] = useDeletePlaceMutation({
    onCompleted() {
      toastContext.showToast(
        '「' + data?.getPlace.name + '」を削除しました',
        'success',
        3000
      );
      history.push('/places');
    },
  });

  if (error) {
    return <GraphQLErrorDialog error={error} />;
  }

  if (deletePlaceResult.error) {
    return <GraphQLMutationErrorDialog error={deletePlaceResult.error} />;
  }

  if (loading || !data || deletePlaceResult.loading) {
    return <Loading />;
  }

  const place = data.getPlace;

  // TODO: created, updated
  return (
    <div className="PlaceIndexPage-container">
      {(function () {
        if (!confirmDelete) {
          return;
        }

        return (
          <ConfirmDialog
            open={true}
            message={<p>{place.name}を削除しますか？</p>}
            onYes={() => deletePlace({ variables: { placeId } })}
            onNo={() => setConfirmDelete(false)}
          />
        );
      })()}
      {(function () {
        if (place.tags.length === 0) {
          return;
        }
        return <div>
          {place.tags.map(tag =>
            <PlaceTag key={tag} className='Place-tag'>{tag}</PlaceTag>)}
        </div>
      })()}
      <h3>責任者</h3>
      <p>{place.chief_name}</p>
      <h3>作業者一覧</h3>
      {(function () {
        if (place.authorized_workers.length === 0) {
          return <p>作業者は設定されていません。</p>
        }

        return (
          <table className="App-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>作業者名</th>
              </tr>
            </thead>
            <tbody>
              {place.authorized_workers.map((worker) => {
                return (
                  <tr key={worker.id}>
                    <td>{worker.screen_id}</td>
                    <td>{worker.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      })()}
      <h3>お客様</h3>
      <p>{place.client_name}</p>
      <h3>写真レイヤの画層色</h3>
      {(function() {
        const pictureLayerColor = place.picture_layer_color ?? '#FFFFFF';
        return (
          <div className='PlaceIndexPage-picture_layer_color'>
            <div className='PlaceIndexPage-picture_layer_color_box' style={{ backgroundColor: pictureLayerColor }}></div>
            <div className='PlaceIndexPage-picture_layer_color_hex'>{pictureLayerColor}</div>
          </div>
        );
      })()}
      <h3>作成日</h3>
      <p>{formatDate(place.created)}</p>
      <div className="PlaceIndexPage-button_container">
        <AuthButton
          authLv={2}
          onClick={() => history.push(`/places/${placeId}/edit`)}
        >
          物件情報変更
        </AuthButton>
      </div>
      <div className="PlaceIndexPage-button_container">
        <AuthButton
          authLv={2}
          onClick={() => setConfirmDelete(true)}
          color="red"
        >
          削除
        </AuthButton>
      </div>
    </div>
  );
};
