import * as React from 'react';
import './ImageSelect.css';

interface IImageSelectOption {
  className?: string;
  selectedValue: string;
  value: string;
  onClick: (value: string) => void;
}

const ImageSelectOption: React.FC<IImageSelectOption> = function (props) {
  let className = 'ImageSelectOption-container';
  if (props.selectedValue === props.value) {
    className += ' ImageSelectOption-selected';
  }

  return (
    <button className={`ImageSelectOption-button ${props.className}`} onClick={() => props.onClick(props.value)}>
      <div className={className}>{props.children}</div>
    </button>
  );
};

export const LineImage: React.FC<{ id: number }> = function (props) {
  switch (props.id) {
    case 0:
      return <img alt="Line Normal" src="/line_normal.png" />;
    case 1:
      return <img alt="Line Dotted" src="/line_dotted.png" />;
    case 2:
      return <img alt="Line Dashed" src="/line_dashed.png" />;
    case 3:
      return <img alt="Line Circle" src="/line_circle.png" />;
  }

  throw new Error('unknown line type: ' + props.id);
};

interface ILineSelectProps {
  value: number;
  optionClassName?: string;
  containerClassName?: string;
  onChange: (value: number) => void;
}

export const LineSelect: React.FC<ILineSelectProps> = function (props) {
  return (
    <div className={props.containerClassName}>
      {[0, 1, 2, 3].map(i =>
        <ImageSelectOption
          key={i}
          className={props.optionClassName}
          selectedValue={props.value.toString()}
          value={i.toString()}
          onClick={(v) => props.onChange(parseInt(v))}
        >
          <LineImage id={i} />
        </ImageSelectOption>
      )}
    </div>
  );
};

interface IArrowEndSelectProps {
  value: number;
  optionClassName?: string;
  onChange: (value: number) => void;
}

export const ArrowEndSelect: React.FC<IArrowEndSelectProps> = function (props) {
  return (
    <div>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="0"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img alt="Arrow-End None" src="/arrow_end_none.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="1"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img alt="Arrow-End Arrow" src="/arrow_end_arrow.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="2"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img alt="Arrow-End Circle" src="/arrow_end_circle.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="3"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img alt="Arrow-End Cross" src="/arrow_end_cross.png" />
      </ImageSelectOption>
    </div>
  );
};

interface IHatchSelectProps {
  value: number;
  optionClassName?: string;
  containerClassName?: string;
  onChange: (value: number) => void;
}

export const HatchImage: React.FC<{ id: number }> = function (props) {
  switch (props.id) {
    case 0:
      return <img alt="Hathc None" src="/hatch_none.png" />;
    case 1:
      return <img alt="Hathc Line" src="/hatch_line.png" />;
    case 2:
      return <img alt="Hathc Line L" src="/hatch_lineL.png" />;
    case 3:
      return <img alt="Hathc Cross" src="/hatch_cross.png" />;
    case 4:
      return <img alt="Hathc Cross L" src="/hatch_crossL.png" />;
    case 5:
      return <img alt="Hathc Fill" src="/hatch_fill.png" />;
  }

  throw new Error('unknown line type: ' + props.id);
};

export const HatchSelect: React.FC<IHatchSelectProps> = function (props) {
  return (
    <div className={props.containerClassName}>
      {[0, 1, 2, 3, 4, 5].map(i =>
        <ImageSelectOption
          className={props.optionClassName}
          selectedValue={props.value.toString()}
          value={i.toString()}
          onClick={(v) => props.onChange(parseInt(v))}
        >
          <HatchImage id={i} />
        </ImageSelectOption>
      )}
    </div>
  );
};
