import * as React from 'react';

type ValidationRule = (value: any) => string | undefined;
export type ValidationRules = { [key: string]: ValidationRule };

type ValidationState = { [key: string]: string | undefined };

type SetValidationState = React.Dispatch<React.SetStateAction<ValidationState>>;

type OnChange = (event: any) => void;

export class Validation {
  private readonly rules: ValidationRules;
  private readonly results: ValidationState;
  private readonly setState: SetValidationState;

  constructor(
    rules: ValidationRules,
    results: ValidationState,
    setState: SetValidationState
  ) {
    this.rules = rules;
    this.results = results;
    this.setState = setState;
  }

  validated(name: string, onChange: OnChange): OnChange {
    return (event) => {
      let message = this.rules[name](event);

      this.setState((prev) => {
        const next = { ...prev };
        next[name] = message;
        return next;
      });

      onChange(event);
    };
  }

  get(name: string): string | undefined {
    return this.results[name];
  }

  isInvalidProperty(name: string): boolean {
    return !(name in this.results) || this.results[name] !== undefined;
  }

  get isInvalid(): boolean {
    const keys = Object.keys(this.results);
    if (keys.length === 0) {
      return true;
    }

    if (Object.keys(this.rules).length !== keys.length) {
      return true;
    }

    for (const name in this.rules) {
      const result = this.results[name];
      if (result) {
        return true;
      }
    }

    return false;
  }

  useState<T>(
    name: string,
    initialState: T,
    initialValid: boolean = false
  ): [T, React.Dispatch<React.SetStateAction<T>>] {
    const validate = this.rules[name];
    const [state, setState] = React.useState(initialState);

    const setStateWithValidation = (value: T | ((prevState: T) => T)) => {
      if (value instanceof Function) {
        value = value(state);
      }

      const message = validate(value);
      this.setState((prev) => {
        const next = { ...prev };
        next[name] = message;
        return next;
      });
      setState(value);
    };

    if (initialValid && !(name in this.results)) {
      this.results[name] = undefined;
    }

    return [state, setStateWithValidation];
  }
}

export function useValidation(rules: { [key: string]: ValidationRule }) {
  const [state, setState] = React.useState<ValidationState>({});
  return new Validation(rules, state, setState);
}
