import * as React from 'react';
import { useHistory, RouteComponentProps } from 'react-router';
import master, { ShapeTypes } from '../Master';
import { Loading } from '../Loading';
import {
  ConfirmDialog,
  GraphQLErrorDialog,
  GraphQLMutationErrorDialog,
} from '../components/Dialogs';
import {
  useGetPlaceCodesLazyQuery,
  useDeletePlaceCodeMutation,
  useGetPlaceCodeMarkerDependenciesLazyQuery,
} from '../generated/graphql';
import './PlaceCodesPage.css';
import { AuthButton } from '../components/Button';
import { ToastContext } from '../components/Toast';
import { getMarkerUsages } from '../utils/MarkerHelper';

type IPlaceCodesPageProps = RouteComponentProps<{ placeId: string }>;

type Code = {
  id: number;
  name: string;
};

export const PlaceCodesPage: React.FC<IPlaceCodesPageProps> = function (props) {
  const placeId = parseInt(props.match.params.placeId);

  const toastContext = React.useContext(ToastContext);
  const history = useHistory();
  const [
    getPlaceCodes,
    { called, data, loading, error },
  ] = useGetPlaceCodesLazyQuery({
    variables: { placeId },
  });
  const [deleteTarget, setDeleteTarget] = React.useState<Code>();
  const [
    getMarkerDependencies,
    markerDepenenciesResult,
  ] = useGetPlaceCodeMarkerDependenciesLazyQuery();
  const [deletePlaceCode, deletePlaceCodeResult] = useDeletePlaceCodeMutation({
    onCompleted() {
      toastContext.showToast('削除しました', 'success', 3000);
      getPlaceCodes();
    },
  });

  if (!called) {
    getPlaceCodes();
  }

  if (error) {
    return <GraphQLErrorDialog error={error} />;
  }

  if (markerDepenenciesResult.error) {
    return <GraphQLErrorDialog error={markerDepenenciesResult.error} />;
  }

  if (deletePlaceCodeResult.error) {
    return <GraphQLMutationErrorDialog error={deletePlaceCodeResult.error} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const place = data.getPlace;

  return (
    <div className="PlaceCodesPage-container">
      <Loading
        open={deletePlaceCodeResult.loading || markerDepenenciesResult.loading}
      />
      {(function () {
        if (markerDepenenciesResult.loading || !markerDepenenciesResult.data) {
          return;
        }
        if (!deleteTarget) {
          return;
        }

        const markers = markerDepenenciesResult.data.getCodeMarkerDependencies;
        let message = `「${deleteTarget.name}」を削除しますか？`;
        if (markers.length > 0) {
          const usages = getMarkerUsages(markers);
          message += ` (使用中: ${usages.used}、履歴: ${usages.total})`;
        }

        return (
          <ConfirmDialog
            open={true}
            message={<p>{message}</p>}
            onYes={() => {
              deletePlaceCode({
                variables: { placeCodeId: deleteTarget.id },
              });
              setDeleteTarget(undefined);
            }}
            onNo={() => setDeleteTarget(undefined)}
          />
        );
      })()}
      <AuthButton
        authLv={2}
        onClick={() => history.push(`/places/${placeId}/codes/new`)}
      >
        新規作成
      </AuthButton>
      <table className="App-table">
        <thead>
          <tr>
            <th>No</th>
            <th>補修コード</th>
            <th>タイプ</th>
            <th>工種</th>
            <th colSpan={2}>操作</th>
          </tr>
        </thead>
        <tbody>
          {place.codes.map((code) => {
            const shape = master.Shapes.find(
              (s) => s.id === code.shape_scheme_id
            );
            if (!shape) {
              throw new Error('unknown');
            }

            let shapeSubType;
            switch (shape.id) {
              case ShapeTypes.Symbol:
                const shapeSymbol = master.ShapeSymbols.find(
                   (t) => t.id === code.shape_symbol_scheme_id
                );
                shapeSubType = shapeSymbol?.name;
                break;
            }

            return (
              <tr key={code.id}>
                <td>{code.id}</td>
                <td>{code.name}</td>
                <td>
                  {shape.name} {shapeSubType}
                </td>
                <td>{code.selected_work?.name ?? '施工管理なし'}</td>
                <td className="App-table_button_cell">
                  <AuthButton
                    authLv={2}
                    mini={true}
                    color={'white'}
                    onClick={() =>
                      history.push(`/places/${placeId}/codes/${code.id}/edit`)
                    }
                  >
                    編集
                  </AuthButton>
                </td>
                <td className="App-table_button_cell">
                  <AuthButton
                    authLv={2}
                    mini={true}
                    color="red"
                    onClick={() => {
                      setDeleteTarget(code);
                      getMarkerDependencies({
                        variables: { placeCodeId: code.id },
                      });
                    }}
                  >
                    削除
                  </AuthButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
