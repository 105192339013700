import * as React from 'react';
import './Validated.css';

interface ValidatedProps {
  message: string | undefined;
}

export const Validated: React.FC<ValidatedProps> = function (props) {
  return (
    <div>
      <div>{props.children}</div>
      {(function () {
        if (props.message) {
          return <p className="Validated-message">{props.message}</p>;
        }
      })()}
    </div>
  );
};
