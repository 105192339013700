import * as React from 'react';
import { Validation } from '../utils/Validation';
import { Input } from './Input';
import './PasswordInput.css';

interface PasswordInputProps {
  validation: Validation;
  onChange: (password: string, check: boolean) => void;
}

export const PasswordInput: React.FC<PasswordInputProps> = function (props) {
  const [password, setPassword] = props.validation.useState('password', '');
  const [checkPassword, setCheckPassword] = React.useState<string>('');

  const check = password === checkPassword;

  props.onChange(password, check);

  let containerHtmlClasses = ['PasswordInput-container'];
  if (!check) {
    containerHtmlClasses.push('PasswordInput-error');
  }

  return (
    <div className={containerHtmlClasses.join(' ')}>
      <div>
        <Input
          label="パスワード"
          className="PasswordInput-password_input"
          type="password"
          onChange={(event) => {
            const password = event.target.value;
            setPassword(password);
          }}
          value={password}
        />
      </div>
      <div className="App-input_container">
        <Input
          label="パスワード（確認用）"
          className="PasswordInput-password_input"
          type="password"
          onChange={(event) => {
            const checkPassword = event.target.value;
            setCheckPassword(checkPassword);
          }}
          value={checkPassword}
        />
      </div>
      {(function () {
        const message = props.validation.get('password');
        if (message) {
          return <p>{message}</p>;
        }

        if (check) {
          return;
        }

        return <p>パスワードが一致しません</p>;
      })()}
    </div>
  );
};
