import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { AuthButton } from '../components/Button';
import { ConfirmDialog, GraphQLErrorDialog } from '../components/Dialogs';
import {
  Sort,
  useDeletePlaceSortMutation,
  useGetConfigTilesQuery,
  useGetPlaceSortMarkerDependenciesLazyQuery,
  useGetPlaceSortsLazyQuery,
} from '../generated/graphql';
import { Loading } from '../Loading';
import { getMarkerUsages } from '../utils/MarkerHelper';
import './PlaceSortsPage.css';

type IPlaceSortsPageProps = RouteComponentProps<{ placeId: string }> & {};

export const PlaceSortsPage: React.FC<IPlaceSortsPageProps> = function (props) {
  const placeId = parseInt(props.match.params.placeId);
  const history = useHistory();
  const [
    getPlaceSorts,
    { called, data, loading, error },
  ] = useGetPlaceSortsLazyQuery({
    variables: {
      placeId: parseInt(props.match.params.placeId),
    },
  });
  const getConfigResult = useGetConfigTilesQuery();
  const [deleteTargetSort, setDeleteTargetSort] = React.useState<Sort>();
  const [
    getMarkerDependencies,
    markerDepenenciesResult,
  ] = useGetPlaceSortMarkerDependenciesLazyQuery();
  const [deleteSort, deleteSortResult] = useDeletePlaceSortMutation({
    onCompleted() {
      getPlaceSorts();
      setDeleteTargetSort(undefined);
    },
  });

  if (!called) {
    getPlaceSorts();
  }

  if (error) {
    return <GraphQLErrorDialog error={error} />;
  }

  if (markerDepenenciesResult.error) {
    return <GraphQLErrorDialog error={markerDepenenciesResult.error} />;
  }

  if (deleteSortResult.error) {
    return <GraphQLErrorDialog error={deleteSortResult.error} />;
  }

  if (loading || !data || getConfigResult.loading || !getConfigResult.data) {
    return <Loading />;
  }

  const tiles = getConfigResult.data.getConfig.tiles;

  return (
    <div className="PlaceSortsPage-container">
      <Loading
        open={deleteSortResult.loading || markerDepenenciesResult.loading}
      />
      {(function () {
        if (markerDepenenciesResult.loading || !markerDepenenciesResult.data) {
          return;
        }
        if (!deleteTargetSort) {
          return;
        }

        const markers = markerDepenenciesResult.data.getSortMarkerDependencies;
        let message = `「${deleteTargetSort.name}」を削除しますか？`;
        if (markers.length > 0) {
          const usages = getMarkerUsages(markers);
          message += ` (使用中: ${usages.used}、履歴: ${usages.total})`;
        }

        return (
          <ConfirmDialog
            open={true}
            message={<p>{message}</p>}
            onYes={() => {
              deleteSort({ variables: { placeSortId: deleteTargetSort.id } });
              setDeleteTargetSort(undefined);
            }}
            onNo={() => setDeleteTargetSort(undefined)}
          />
        );
      })()}
      <AuthButton
        authLv={2}
        onClick={() => history.push(`/places/${placeId}/sorts/new`)}
      >
        新規作成
      </AuthButton>
      {(function () {
        if (data.getPlace.sorts.length == 0) {
          return <p>補修分類が登録されていません。</p>
        }

        return (
          <table className="App-table">
            <thead>
              <tr>
                <th>No</th>
                <th>名前</th>
                <th>前置文字列</th>
                <th>タイル種別</th>
                <th colSpan={2}>操作</th>
              </tr>
            </thead>
            <tbody>
              {data.getPlace.sorts.map((sort) => {
                return (
                  <tr>
                    <td>{sort.id}</td>
                    <td>{sort.name}</td>
                    <td>{sort.prefix}</td>
                    <td>{tiles.find(t => t.id === sort.shape_tile_id)?.name}</td>
                    <td className="App-table_button_cell">
                      <AuthButton
                        authLv={2}
                        mini={true}
                        color={'white'}
                        onClick={() =>
                          history.push(`/places/${placeId}/sorts/${sort.id}/edit`)
                        }
                      >
                        編集
                      </AuthButton>
                    </td>
                    <td className="App-table_button_cell">
                      <AuthButton
                        authLv={2}
                        color={'red'}
                        mini={true}
                        onClick={() => {
                          setDeleteTargetSort(sort);
                          getMarkerDependencies({
                            variables: { placeSortId: sort.id },
                          });
                        }}
                      >
                        削除
                      </AuthButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      })()}
      
    </div>
  );
};
