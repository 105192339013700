import * as React from 'react';
import { useHistory } from 'react-router';
import Cookies from 'universal-cookie';
import { AppContext } from './AppContext';
import { Button } from './components/Button';
import './stylesheets/Header.css';

const HeaderLogo: React.FC = function () {
  const history = useHistory();
  return (
    <div className="Header-logo_button" onClick={() => history.push("/")}>
      <div className="Header-icon App-icon">
        <img alt="App Icon" src="/appicon.png" />
      </div>
      <div className="Header-title App-title">スマート検査報告書作成システム ST</div>
    </div>
  );
};

/**
 * アプリケーションのヘッダー
 */
export const Header: React.FC = function () {
  const appContext = React.useContext(AppContext);
  const history = useHistory();
  const cookies = new Cookies();

  let logoutButton;
  if (cookies.get('auth-token')) {
    logoutButton = (
      <div className="Header-logout">
        <Button
          color="white"
          mini={true}
          onClick={() => {
            cookies.remove('auth-token');
            if (appContext.ready) {
              appContext.dispatch({ type: 'removeAccount' });
            }
            history.push('/');
          }}
        >
          ログアウト
        </Button>
      </div>
    );
  }

  if (!appContext.state.appInfo) {
    return (
      <header className="Header-header">
        <HeaderLogo />
        <div className="Header-navigator"></div>
        {logoutButton}
      </header>
    );
  }

  if (!appContext.state.me) {
    return (
      <header className="Header-header">
        <HeaderLogo />
        <div className="Header-navigator">{appContext.state.appInfo.companyName}</div>
        {logoutButton}
      </header>
    );
  }

  return (
    <header className="Header-header">
      <HeaderLogo />
      <div className="Header-navigator">
        <span>{appContext.state.appInfo.companyName}</span>
        <span className="Header-account_separator">·</span>
        <span className="Header-account_name">{appContext.state.me.name}</span>
        <span className="Header-account_san">さん</span>
      </div>
      {logoutButton}
    </header>
  );
};
