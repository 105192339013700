import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { AuthButton, Button } from '../components/Button';
import { ConfirmDialog, GraphQLErrorDialog, NotifyDialog } from '../components/Dialogs';
import { useDeletePlaceWorkMutation, useGetPlaceWorksLazyQuery } from '../generated/graphql';
import { Loading } from '../Loading';
import './PlaceWorkListPage.css';

type IPlaceWorkListPageProps = RouteComponentProps<{ placeId: string }> & {};

export const PlaceWorkListPage: React.FC<IPlaceWorkListPageProps> = function (props) {
  const placeId = parseInt(props.match.params.placeId);
  const history = useHistory();
  const [deletePlaceWorkFailed, setDeletePlaceWorkFailed] = React.useState(false);
  const [getPlaceWorks, getPlaceWorksResult] = useGetPlaceWorksLazyQuery({ variables: { placeId } });
  const [deletePlaceWork, deletePlaceWorkResult] = useDeletePlaceWorkMutation({
    onCompleted(result) {
      // 削除を実行するたびにリストを更新する
      getPlaceWorks();

      // 工種が使われていたりする場合は削除できずにfalseが返る
      setDeletePlaceWorkFailed(!result.deletePlaceWork);
    }
  });
  // 確認ダイアログを出すために削除対象を一旦状態に保存する
  const [deleteTarget, setDeleteTarget] = React.useState<{ id: number, name: string }>();

  if (!getPlaceWorksResult.called) {
    getPlaceWorks();
  }

  if (getPlaceWorksResult.error) {
    return <GraphQLErrorDialog error={getPlaceWorksResult.error} />
  }
  if (deletePlaceWorkResult.error) {
    return <GraphQLErrorDialog error={deletePlaceWorkResult.error} />
  }

  return (
    <div className="PlaceWorkListPage-container">
      <Loading open={getPlaceWorksResult.loading || deletePlaceWorkResult.loading} />
      {(function () {
        if (!deleteTarget) {
          return;
        }

        return (
          <ConfirmDialog
            open={!!deleteTarget}
            message={<p>{deleteTarget.name}を削除しますか？</p>}
            onYes={() => {
              deletePlaceWork({ variables: { placeWorkId: deleteTarget.id } });
              setDeleteTarget(undefined);
            }}
            onNo={() => {
              setDeleteTarget(undefined);
            }}
            />
        );
      })()}
      <NotifyDialog
        open={deletePlaceWorkFailed}
        message={<p>工種が使用されているため削除できません。</p>}
        onYes={() => setDeletePlaceWorkFailed(false)}
        />
      <AuthButton authLv={2} onClick={() => history.push(`/places/${placeId}/works/new`)}>新規作成</AuthButton>
      {(function () {
        const data = getPlaceWorksResult.data;
        if (!data) {
          return;
        }

        if (data.getPlace.works.length === 0) {
          return <p>工種は登録されていません。</p>
        }

        return (
          <table className='App-table'>
            <thead>
              <tr>
                <th>No</th>
                <th className='PlaceWorkListPage-work_name_cell'>工種</th>
                <th>工程</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.getPlace.works.map((work, index) => (
                <tr key={index}>
                  <td className='App-table_number_cell'>{index + 1}</td>
                  <td>{work.name}</td>
                  <td>
                    <ul>
                    {[work.default_step, ...work.steps].map((step, i) => (
                        <li key={i}>{step.name}</li>
                    ))}
                    </ul>
                  </td>
                  <td className='App-table_button_cell'>
                    <Button mini color='white' onClick={() => history.push(`/places/${placeId}/works/${work.id}/edit`)}>編集</Button>
                  </td>
                  <td className='App-table_button_cell'>
                    <Button mini color='red' onClick={() => setDeleteTarget(work)}>削除</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      })()}
    </div>
  );
};
