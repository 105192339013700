import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  useUpdatePlaceSortMutation,
  useGetPlaceSortLazyQuery,
  useGetConfigTilesQuery,
} from '../generated/graphql';
import { Loading } from '../Loading';
import { ToastContext } from '../components/Toast';
import { GraphQLMutationErrorDialog } from '../components/Dialogs';
import { UpdateButton } from '../components/Button';
import { Input } from '../components/Input';
import { useValidation } from '../utils/Validation';
import { ValidationRules } from '../utils/ValidationRules';
import './PlaceCreateSortPage.css';

type PlaceCreateSortPageProps = RouteComponentProps<{ placeId: string }> & {
  existingId?: number;
};

export const PlaceCreateSortPage: React.FC<PlaceCreateSortPageProps> = function (
  props
) {
  const placeId = parseInt(props.match.params.placeId);
  const history = useHistory();
  const toastContext = React.useContext(ToastContext);
  const validation = useValidation(ValidationRules.PlaceSort);
  const [shapeTileSchemeId, setShapeTileSchemeId] = React.useState(-1);
  const [name, setName] = validation.useState('name', '');
  const [prefix, setPrefix] = React.useState('');
  const getConfigResult = useGetConfigTilesQuery({
    onCompleted(data) {
      if (shapeTileSchemeId === -1) {
        setShapeTileSchemeId(data.getConfig.tiles[0].id);
      }
    }
  });
  const [getPlaceSort, getPlaceSortResult] = useGetPlaceSortLazyQuery({
    onCompleted(data) {
      setName(data.getPlaceSort.name);
      setShapeTileSchemeId(data.getPlaceSort.shape_tile_id);
    },
  });
  const [addPlaceSort, addPlaceSortResult] = useUpdatePlaceSortMutation({
    onCompleted() {
      toastContext.showToast('補修種別を作成しました', 'success', 10000);
    },
  });

  if (addPlaceSortResult.called) {
    if (addPlaceSortResult.error) {
      return <GraphQLMutationErrorDialog error={addPlaceSortResult.error} />;
    }
    if (addPlaceSortResult.loading || !addPlaceSortResult.data) {
      return <Loading />;
    }

    history.push(`/places/${placeId}/sorts`);
  }

  if (props.existingId && !getPlaceSortResult.called) {
    getPlaceSort({
      variables: { placeSortId: props.existingId },
    });
  }

  if (getPlaceSortResult.called) {
    if (getPlaceSortResult.error) {
      return <GraphQLMutationErrorDialog error={getPlaceSortResult.error} />;
    }

    if (getPlaceSortResult.loading || !getPlaceSortResult.data) {
      return <Loading />;
    }
  }

  if (getConfigResult.loading || !getConfigResult.data) {
    return <Loading />;
  }

  const tiles = getConfigResult.data.getConfig.tiles;

  return (
    <div className="PlaceCreateSortPage-container">
      <h1>補修種別作成</h1>
      <div className="App-form">
        <div className="App-input_container PlaceCreateSortPage-input_container">
          <label className="App-label">補修種別名</label>
          <Input
            type="string"
            onChange={(event) => setName(event.target.value)}
            value={name}
            validationMessage={validation.get('name')}
          />
        </div>
        <div className="App-input_container PlaceCreateSortPage-input_container">
          <label className="App-label">前置文字列</label>
          <Input
            type="string"
            onChange={(event) => setPrefix(event.target.value)}
            value={prefix}
          />
        </div>
        <div className="App-input_container PlaceCreateSortPage-input_container">
          <label className="App-label">タイルの種類</label>
          <select
            className="PlaceCreateCodePage-shape_tile_select"
            value={shapeTileSchemeId}
            onChange={(event) =>
              setShapeTileSchemeId(parseInt(event.target.value))
            }
          >
            {tiles.map((shapeTile) => (
              <option value={shapeTile.id}>{shapeTile.name}</option>
            ))}
          </select>
        </div>
        <div className="PlaceCreateSortPage-button_container">
          <UpdateButton
            create={!props.existingId}
            disabled={validation.isInvalid}
            onClick={() => {
              addPlaceSort({
                variables: {
                  input: {
                    id: props.existingId,
                    place_id: placeId,
                    name: name,
                    prefix: prefix,
                    shape_tile_id: shapeTileSchemeId
                  },
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
