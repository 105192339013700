import * as React from 'react';
import { useHistory } from 'react-router';
import { Input, Checkbox } from '../components/Input';
import { Button } from '../components/Button';
import './AdminCreateAccountPage.css';
import { ApiClient } from '../utils/ApiClient';
import {
  useGetFrameLazyQuery,
  useUpdateFrameMutation,
} from '../generated/graphql';
import { Loading } from '../Loading';
import {
  GraphQLErrorDialog,
  GraphQLMutationErrorDialog,
} from '../components/Dialogs';
import * as Const from '../Const';

interface AdminCreateFramePageProps {
  existingId?: number;
}

export const AdminCreateFramePage: React.FC<AdminCreateFramePageProps> = function (
  props
) {
  const history = useHistory();
  const [uploading, startUpload] = React.useState(false);
  const [frameName, setFrameName] = React.useState<string>('');
  const [frameType, setFrameType] = React.useState<number>(1);
  const [images, setImages] = React.useState<(File | null)[]>([]);
  const [selectedUploadedImages, selectUploadedImages] = React.useState<
    boolean[]
  >([]);
  const [dxfFile, setDxfFile] = React.useState<File | null>(null);
  const [getFrame, getFrameResult] = useGetFrameLazyQuery({
    onCompleted(data) {
      const frame = data.getFrame;
      setFrameName(frame.name);
      setFrameType(frame.frame_type);
    },
  });
  const [updateFrame, updateFrameResult] = useUpdateFrameMutation();

  if (props.existingId) {
    if (!getFrameResult.called) {
      getFrame({ variables: { frameId: props.existingId } });
    }

    if (getFrameResult.error) {
      return <GraphQLErrorDialog error={getFrameResult.error} />;
    }

    if (getFrameResult.loading || !getFrameResult.data) {
      return <Loading />;
    }
  }

  if (updateFrameResult.called) {
    if (updateFrameResult.error) {
      return <GraphQLMutationErrorDialog error={updateFrameResult.error} />;
    }

    if (updateFrameResult.loading) {
      return <Loading />;
    }

    history.push(`/configs`);
  }

  const pictureIndices = [];
  for (let i = 0; i < images.length; i++) {
    pictureIndices[i] = i;
  }

  return (
    <div>
      <Loading open={uploading} />
      <h2>図枠登録</h2>
      <p>1. 名前</p>
      <Input
        value={frameName}
        onChange={(event) => setFrameName(event.target.value)}
      />
      {/*
      <p>2. 図枠種類</p>
      <select
        value={frameType}
        className="App-input-container"
        onChange={(event) => setFrameType(parseInt(event.target.value))}
      >
        {master.Frames.map((frame) => (
          <option value={frame.id}>{frame.name}</option>
        ))}
      </select>
      */}
      <p>2. 図枠ファイルを選択 (32MBまで)</p>
      <div>
        {(function () {
          if (getFrameResult) {
            return (
              <div>
                <h4>現在のデータ</h4>
                <p>{getFrameResult.data?.getFrame.dxf_file_name}</p>
              </div>
            );
          }
        })()}
        <Input
          type="file"
          onChange={(event) => {
            const files = event.target.files;
            if (!files || files.length === 0) {
              setDxfFile(null);
              return;
            }

            // TODO: ファイルが複数指定されているときの処理

            const file = files[0];
            setDxfFile(file);
          }}
        />
        <div>
          <p>3. 図面ファイルに関連する画像がある場合は追加（任意、32BMまで）</p>
          <table className="App-table">
            <thead>
              <th colSpan={2}>データ</th>
              <th>操作</th>
            </thead>
            <tbody>
              {(function () {
                if (getFrameResult.data) {
                  const dxfImages = getFrameResult.data.getFrame.dxf_images;
                  return dxfImages.map((image, index) => {
                    return (
                      <tr>
                        <td>{image.file_name}</td>
                        <td>
                          <a href={`${Const.API_URL}${image.file_url}`}>
                            ダウンロード
                          </a>
                        </td>
                        <td>
                          <Checkbox
                            value={selectedUploadedImages[index]}
                            onChange={(value) => {
                              const next = selectedUploadedImages.slice();
                              next[index] = value;
                              selectUploadedImages(next);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  });
                }
              })()}
              {pictureIndices.map((index) => {
                return (
                  <tr key={`picture-input-${index}`}>
                    <td colSpan={2}>
                      <Input
                        type="file"
                        onChange={(event) => {
                          const next = images.slice();
                          if (
                            !event.target.files ||
                            event.target.files.length === 0
                          ) {
                            next[index] = null;
                          } else {
                            next[index] = event.target.files[0];
                          }

                          setImages(next);
                        }}
                      />
                    </td>
                    <td>
                      <Button
                        color="red"
                        onClick={() =>
                          setImages(images.filter((_, i) => i !== index))
                        }
                      >
                        削除
                      </Button>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={3}>
                  <Button onClick={() => setImages((prev) => [...prev, null])}>
                    写真の追加
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button
          onClick={async () => {
            if (uploading) {
              return;
            }
            startUpload(true);

            const apiClient = new ApiClient();

            let dxf;
            if (dxfFile) {
              // アップロードするファイルの中身を読み込む
              const [dxfFileUrl, dxfFileSize] = await apiClient.upload(dxfFile);

              dxf = {
                file_name: dxfFile.name,
                file_url: dxfFileUrl,
                file_size: dxfFileSize,
              };
            }

            const dxfImages = await Promise.all(
              images
                .filter((_): _ is File => _ != null)
                .map(async (imageFile) => {
                  const [fileUrl] = await apiClient.upload(imageFile);
                  return {
                    file_name: imageFile.name,
                    file_url: fileUrl,
                  };
                })
            );

            const uploadedDxfImages = [];
            if (getFrameResult.data) {
              uploadedDxfImages.push(
                ...getFrameResult.data.getFrame.dxf_images
                  .map((image) => image.id)
                  .filter((_, i) => selectedUploadedImages[i])
              );
            }

            startUpload(false);

            updateFrame({
              variables: {
                input: {
                  id: props.existingId,
                  name: frameName,
                  frame_type: frameType,
                  dxf,
                  dxf_images: dxfImages,
                  uploaded_dxf_images: uploadedDxfImages,
                },
              },
            });
          }}
        >
          アップロード
        </Button>
      </div>
    </div>
  );
};
