import * as React from 'react';
import './SuperAdminPage.css';
import {
  useGetDeviceCountLimitLazyQuery,
  useUpdateDeviceCountLimitMutation,
} from '../generated/graphql';
import { ToastContext } from '../components/Toast';
import { Button } from '../components/Button';
import { stat } from 'fs';

export const SuperAdminPage: React.FC = function () {
  interface ISuperAdminState {
    quantity: string;
    effectiveDays: string;
    updating: boolean;
    loading: boolean;
  }

  const toastContext = React.useContext(ToastContext);
  const [state, setState] = React.useState<ISuperAdminState>({
    quantity: '',
    effectiveDays: '',
    updating: false,
    loading: true,
  });
  const [updateLimit] = useUpdateDeviceCountLimitMutation({
    onCompleted() {
      toastContext.showToast('制限を更新しました。', 'success', 10000);
      setState((prev) => ({ ...prev, updating: false }));
    },
  });

  const [
    getDeviceCountLimit,
    getDeviceCountLimitResult,
  ] = useGetDeviceCountLimitLazyQuery();

  if (state.loading) {
    if (!getDeviceCountLimitResult.called) {
      getDeviceCountLimit();
    }
    if (getDeviceCountLimitResult.data) {
      setState({
        quantity: getDeviceCountLimitResult.data!.getDeviceCountLimit.quantity.toString(),
        effectiveDays: getDeviceCountLimitResult.data!.getDeviceCountLimit.effective_days.toString(),
        updating: false,
        loading: false,
      });
    }
  }

  function submit() {
    setState((prev) => ({ ...prev, updating: true }));
    updateLimit({
      variables: {
        input: {
          quantity: parseInt(state.quantity!),
          effective_days: parseFloat(state.effectiveDays!),
        },
      },
    });
  }

  return (
    <div className="SuperAdminPageContainer">
      <h1>スギテック管理画面</h1>
      <div className="TableWrapper">
        <div className="Row">
          <div className="Cell">制限台数</div>
          <div className="Cell">
            <input
              type="number"
              onChange={(event) => {
                const quantity = event.target.value;
                setState((prev) => ({ ...prev, quantity }));
              }}
              value={state.quantity}
            />
          </div>
        </div>
        <div className="Row">
          <div className="Cell">台数にカウントする期間（日）</div>
          <div className="Cell">
            <input
              type="number"
              onChange={(event) => {
                const effectiveDays = event.target.value;
                setState((prev) => ({ ...prev, effectiveDays }));
              }}
              value={state.effectiveDays}
            />
          </div>
        </div>
        <Button
          className="UpdateButton"
          onClick={submit}
          disabled={state.updating}
        >
          {state.updating ? '更新中...' : '更新'}
        </Button>
      </div>
    </div>
  );
};
