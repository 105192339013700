import * as React from 'react';
import dateformat from 'dateformat';
import './PlaceListPage.css';
import { Link, useHistory } from 'react-router-dom';
import { Loading } from '../Loading';
import { useGetPlacesQuery } from '../generated/graphql';
import { GraphQLErrorDialog } from '../components/Dialogs';
import { AuthButton, Button } from '../components/Button';
import { PlaceTag } from '../components/PlaceTag';

export const PlaceListPage: React.FC = function () {
  const { loading, data, error } = useGetPlacesQuery();
  const history = useHistory();
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [invertSelectedTags, setInvertSelectedTags] = React.useState<boolean>(false);

  if (error) {
    return <GraphQLErrorDialog error={error} />;
  }

  // 物件IDが選択されているタグかどうかを判定するマップを作成する
  // 何も選択されていない場合は undefined に設定する
  let selectedTagPlaceIds: { [key: number]: boolean } | undefined;
  if (selectedTags.length > 0 && data) {
    // 選択されているタグの名前からタグに紐付けられている物件IDのリストを取得し、物件IDを一つのリストにする
    const taggedPlaceIds = data.getPlaceTags
      .filter(placeTag => selectedTags.indexOf(placeTag.name) !== -1)
      .flatMap(placeTag => placeTag.place_ids);

    // タグに紐付いている物件がある場合にマップを作成する
    if (taggedPlaceIds.length > 0) {
      selectedTagPlaceIds = {};
      for (const taggedPlaceId of taggedPlaceIds) {
        selectedTagPlaceIds[taggedPlaceId] = true;
      }
    }
  }

  return (
    <div className="PlaceListPage-container">
      <Loading open={loading || !data} />
        <h1>物件一覧</h1>
      <AuthButton authLv={2} onClick={() => history.push('/places/new')}>
        新規作成
      </AuthButton>
      <div className="PlaceListPage-tag_container">
        {data?.getPlaceTags.map(tag => {
          const selected = selectedTags.indexOf(tag.name) !== -1;
          return (
            <PlaceTag
              key={tag.name}
              selected={selected}
              onClick={() => {
                if (selected) {
                  setSelectedTags(selectedTags.filter(t => t !== tag.name));
                } else {
                  setSelectedTags(selectedTags.concat([tag.name]));
                }
              }}
              >
              {tag.name}
            </PlaceTag>
          );
        })}
      </div>
      <div className="PlaceListPage-tag_operations">
        <div className='PlaceListPage-tag_operation_button'>
          <Button color='white' mini onClick={() => setSelectedTags([])}>タグの選択状態をクリア</Button>
        </div>
        <div className='PlaceListPage-tag_operation_button'>
          <Button color={invertSelectedTags ? 'red' : 'white'} mini onClick={() => setInvertSelectedTags(!invertSelectedTags)}>
            {invertSelectedTags ? '選択したタグの物件を表示' : '選択したタグ以外の物件を表示'}
          </Button>
        </div>
      </div>
      <table className="App-table">
        <thead>
          <tr>
            <th>現場名</th>
            <th>面数</th>
            <th>責任者</th>
            <th>お客様</th>
            <th>作成日</th>
            <th>更新日</th>
          </tr>
        </thead>
        <tbody>
          {(function () {
            if (!data) {
              return;
            }

            let places;
            if (!selectedTagPlaceIds) {
              // タグが選択されていないならすべて表示する
              places = data.getPlaces;
            } else {
              places = data.getPlaces
                // タグが選択されている場合はタグに紐付いている物件のみを表示する
                .filter(place => {
                  const isSelectedTagPlace = selectedTagPlaceIds![place.id];
                  if (invertSelectedTags) {
                    // 選択したタグ以外の物件を表示するモードの場合は判定を入れ替える
                    return !isSelectedTagPlace;
                  }
                  return isSelectedTagPlace;
                })
            }

            return places.map((place) => {
              if (!place) {
                return new Error();
              }
              return (
                <tr key={place.id}>
                  <td>
                    <Link to={`/places/${place.id}`}>{place.name}</Link>
                  </td>
                  <td>{place.faces.length}</td>
                  <td>{place.chief_name}</td>
                  <td>{place.client_name}</td>
                  <td>{dateformat(place.created, 'yyyy年m月d日 HH:MM:ss')}</td>
                  <td>{dateformat(place.updated, 'yyyy年m月d日 HH:MM:ss')}</td>
                </tr>
              );
            })
          })()}
        </tbody>
      </table>
    </div>
  );
};
