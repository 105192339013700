import * as React from 'react';

/**
 * 404 Not Found
 */
export const NotFoundPage: React.FC = function () {
  return (
    <div>
      <p>404 Not Found - お探しのページは見つかりませんでした。</p>
    </div>
  );
};
