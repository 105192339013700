import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  useGetPlaceWorkLazyQuery, useUpdatePlaceWorkMutation,
} from '../generated/graphql';
import { Loading } from '../Loading';
import { ToastContext } from '../components/Toast';
import { GraphQLMutationErrorDialog } from '../components/Dialogs';
import { Button, UpdateButton } from '../components/Button';
import { Input } from '../components/Input';
import { useValidation } from '../utils/Validation';
import { ValidationRules } from '../utils/ValidationRules';
import './PlaceCreateWorkPage.css';

type IPlaceCreateWorkPageProps = RouteComponentProps<{ placeId: string, workId?: string }>

export const PlaceCreateWorkPage: React.FC<IPlaceCreateWorkPageProps> = function (
  props
) {
  const placeId = parseInt(props.match.params.placeId);
  const placeWorkId = props.match.params.workId === undefined ? null : parseInt(props.match.params.workId);
  const toastContext = React.useContext(ToastContext);
  const history = useHistory();
  const validation = useValidation(ValidationRules.PlaceWork);

  // 工種の名前
  const [name, setName] = validation.useState('name', '');
  // 基本工程の名前
  const [defaultStepName, setDefaultStepName] = validation.useState('defaultStepName', '初期工程', true);
  // 新しく作成される工程のリスト
  const [createdSteps, setCreatedSteps] = React.useState<{ name: string }[]>([]);
  // 更新が必要な工程のリスト
  const [updatedSteps, setUpdatedSteps] = React.useState<{ id: number, name: string }[]>([]);
  // 削除する工程IDのリスト
  const [deletedStepIds, setDeletedStepIds] = React.useState<number[]>([]);

  // データを編集する場合に既存のデータを取得するためのクエリ
  const [getPlaceWork, getPlaceWorkResult] = useGetPlaceWorkLazyQuery({
    onCompleted(data) {
      const work = data.getPlaceWork;
      setName(work.name);
      setDefaultStepName(work.default_step.name);
    },
  });

  // データの作成もしくは更新を行うクエリ
  const [updatePlaceWork, updatePlaceWorkResult] = useUpdatePlaceWorkMutation({
    onCompleted() {
      let message;
      if (placeWorkId) {
        message = '工種を更新しました';
      } else {
        message = '工種を作成しました';
      }
      toastContext.showToast(message, 'success', 3000);

      history.push(`/places/${placeId}/works`);
    },
  });

  // 既存のデータの修正を行う場合は現在のデータを取得する
  if (placeWorkId && !getPlaceWorkResult.called) {
    getPlaceWork({
      variables: { placeWorkId: placeWorkId },
    });
  }

  // エラー処理
  if (getPlaceWorkResult.error) {
    return <GraphQLMutationErrorDialog error={getPlaceWorkResult.error} />;
  }
  if (updatePlaceWorkResult.error) {
    return <GraphQLMutationErrorDialog error={updatePlaceWorkResult.error} />;
  }

  return (
    <div className="PlaceCreateWorkPage-container">
      <Loading open={updatePlaceWorkResult.loading || getPlaceWorkResult.loading} />
      <h1>工種追加</h1>
      <div className="App-form">
        <div className="App-input_container">
          <label className="App-label">工種名</label>
          <Input
            type="string"
            onChange={(event) => setName(event.target.value)}
            value={name}
            validationMessage={validation.get('name')}
          />
        </div>
        <div className="App-input_container">
          <label className="App-label">基本工程</label>
          <Input
            type="string"
            onChange={(event) => setDefaultStepName(event.target.value)}
            value={defaultStepName}
            validationMessage={validation.get('defaultStepName')}
          />
        </div>
        <div>
          <label className="App-label">追加工程</label>
          <table className="App-table">
            <thead>
              <tr>
                <th>工程名</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(function () { // 既存のデータの一覧表示と更新処理
                if (!getPlaceWorkResult.data) {
                  return;
                }

                return getPlaceWorkResult.data.getPlaceWork.steps
                  .filter(step => deletedStepIds.indexOf(step.id) === -1)
                  .map(step => {
                    const updatedStepIndex = updatedSteps.findIndex(s => s.id === step.id);
                    return (
                      <tr>
                        <td>
                          <Input
                            type="string"
                            onChange={(event) => {
                              if (updatedStepIndex === -1) {
                                setUpdatedSteps([...updatedSteps, {
                                  id: step.id,
                                  name: event.target.value,
                                }]);
                              } else {
                                const nextUpdatedSteps = [...updatedSteps];
                                nextUpdatedSteps[updatedStepIndex] = {
                                  id: step.id,
                                  name: event.target.value,
                                };
                                setUpdatedSteps(nextUpdatedSteps);
                              }
                            }}
                            value={updatedSteps[updatedStepIndex]?.name ?? step.name}
                          />
                        </td>
                        <td>
                          <Button mini color={'red'} onClick={() => setDeletedStepIds([...deletedStepIds, step.id])}>
                            削除
                          </Button>
                        </td>
                      </tr>
                    );
                  });
              })()}
              {(function () {
                return createdSteps.map((step, index) => {
                  return (
                    <tr>
                      <td>
                        <Input
                          type="string"
                          onChange={(event) => {
                            const nextCreatedSteps = [...createdSteps];
                            nextCreatedSteps[index] = { name: event.target.value };
                            setCreatedSteps(nextCreatedSteps);
                          }}
                          value={step.name}
                        />
                      </td>
                      <td>
                        <Button mini color={'red'} onClick={() => {
                          const nextCreatedSteps = [...createdSteps];
                          nextCreatedSteps.splice(index, 1);
                          setCreatedSteps(nextCreatedSteps);
                        }}>
                          削除
                        </Button>
                      </td>
                    </tr>
                  );
                });
              })()}
              <tr>
                <td colSpan={3}>
                  <Button onClick={() => setCreatedSteps([...createdSteps, { name: '' }])}>追加</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="PlaceCreateWorkPage-button_container">
          <UpdateButton
            create={!placeWorkId}
            disabled={validation.isInvalid}
            onClick={() => {
              updatePlaceWork({
                variables: {
                  input: {
                    id: placeWorkId,
                    place_id: placeId,
                    name: name,
                    default_step: {
                      name: defaultStepName
                    },
                    created_steps: createdSteps,
                    updated_steps: updatedSteps,
                    deleted_step_ids: deletedStepIds,
                  },
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
