import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useGetPlaceCodesAndSortsLazyQuery, useGetPlaceCodesAndSortsQuery, useUpdatePlaceCodeAndSortDisabledMutation, useUpdatePlaceCodesAndSortsMutation } from '../generated/graphql';
import { HatchImage, LineImage } from '../components/ImageSelect';
import { Loading } from '../Loading';
import { Button } from '../components/Button';
import { GraphQLErrorDialog } from '../components/Dialogs';
import './PlaceCodeAndSortPage.css';

type IPlaceCodeAndSortPageProps = RouteComponentProps<{ placeId: string }> & {};

export const PlaceCodeAndSortPage: React.FC<IPlaceCodeAndSortPageProps> = function (props) {
  const placeId = parseInt(props.match.params.placeId);
  const history = useHistory();

  const [getCodesAndSorts, getCodesAndSortsResult] = useGetPlaceCodesAndSortsLazyQuery({
    variables: { placeId }
  });
  const [updateDisabled, updateDisabledResult] = useUpdatePlaceCodeAndSortDisabledMutation({
    onCompleted() {
      getCodesAndSorts();
    }
  });
  const [updateCodeAndSortConfig, updateCodeAndSortConfigResult] = useUpdatePlaceCodesAndSortsMutation({
    onCompleted() {
      getCodesAndSorts();
    }
  });

  if (!getCodesAndSortsResult.called) {
    getCodesAndSorts();
  }

  if (getCodesAndSortsResult.error) {
    return <GraphQLErrorDialog error={getCodesAndSortsResult.error} />;
  }

  if (getCodesAndSortsResult.loading || !getCodesAndSortsResult.data) {
    return <Loading />;
  }

  const codes: { id: number | null, name: string }[] = [];
  for (const code of getCodesAndSortsResult.data.getPlace.codes) {
    codes.push(code);
  }
  codes.push({ id: null, name: '指定なし' });

  const sorts: { id: number | null, name: string }[] = [];
  for (const code of getCodesAndSortsResult.data.getPlace.sorts) {
    sorts.push(code);
  }
  sorts.push({ id: null, name: '指定なし' });

  const enabledConfigs = [];
  const disabledConfigs = [];
  for (const sort of sorts) {
    for (const code of codes) {
      const config = getCodesAndSortsResult.data.getPlace.code_sort_configs
        .find(config => config.code_id === code.id && config.sort_id === sort.id);
      if (config?.disabled) {
        disabledConfigs.push({ code, sort, config });
      } else {
        enabledConfigs.push({ code, sort, config });
      }
    }
  }

  const codeAndSortConfigs = enabledConfigs.concat(disabledConfigs);

  return (
    <div className="PlaceSortsPage-container">
      <Loading open={updateDisabledResult.called && updateDisabledResult.loading} />
      <table className="App-table">
        <thead>
          <th>補修分類</th>
          <th>補修コード</th>
          <th>前置文字列</th>
          <th>画層の色</th>
          <th>線種</th>
          <th>テクスチャ</th>
          <th colSpan={2}>操作</th>
        </thead>
        <tbody>
        {codeAndSortConfigs.map((args => {
          const { code, sort, config } = args;
          
          const prefix = config?.prefix ?? "";
          const color = config?.color ?? '#FFFFFF';
          const lineType = config?.line_type ?? 0;
          const hatchType = config?.hatch_type ?? 0;
          const disabled = config?.disabled ?? false;

          let className;
          if (disabled) {
            className = "PlaceCodeAndSortPage-disabled-config";
          }

          return (
            <tr className={className}>
              <td>{sort.name}</td>
              <td>{code.name}</td>
              <td>{prefix}</td>
              <td style={{ backgroundColor: color }}>
                {color}
              </td>
              <td>
                <div className="PlaceCodeAndSortPage-image">
                  <LineImage id={lineType} />
                </div>
              </td>
              <td>
                <div className="PlaceCodeAndSortPage-image">
                  <HatchImage id={hatchType} />
                </div>
              </td>
              <td>
                <Button 
                  onClick={() => history.push(`/places/${placeId}/codes_and_sorts/${code.id ?? 'none'}/${sort.id ?? 'none'}/edit`)}
                  mini={true}
                  color="white"
                >
                  編集
                </Button>
              </td>
              <td>
                <Button 
                  onClick={() => {
                    // コンフィグは設定されるときに初めて組み合わせに対する設定を作成するので
                    // 存在しない場合がある
                    if (!config) {
                      updateCodeAndSortConfig({
                        variables: {
                          input: {
                            place_id: placeId,
                            code_id: code.id,
                            sort_id: sort.id,
                            prefix: "",
                            color: color,
                            line_type: lineType,
                            hatch_type: hatchType,
                            // 設定がないデフォルトではdisabledはfalseになっているので
                            // このルートで設定のデータを作成する場合は初期をtrueにする
                            disabled: true,  
                          }
                        }
                      });
                    } else {
                      // 設定が存在する場合はフラグの入れ替えだけ行う
                      updateDisabled({
                        variables: {
                          placeId: placeId,
                          codeId: code.id,
                          sortId: sort.id,
                          disabled: !disabled,
                        }
                      });
                    }

                  }}
                  mini={true}
                  color={disabled ? 'normal' : 'red'}
                >
                  {disabled ? '有効化' : '無効化'}
                </Button>
              </td>
            </tr>
          );
        }))}
        </tbody>
      </table>
    </div>
  );
};
