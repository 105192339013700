import * as React from 'react';
import { useHistory } from 'react-router';
import {
  useUpdateCustomerMutation,
  useGetCustomerLazyQuery,
} from '../generated/graphql';
import { Loading } from '../Loading';
import { ToastContext } from '../components/Toast';
import { GraphQLErrorDialog } from '../components/Dialogs';
import { useValidation } from '../utils/Validation';
import { ValidationRules } from '../utils/ValidationRules';
import { UpdateButton } from '../components/Button';
import { Input } from '../components/Input';
import './AdminCreateAccountPage.css';

interface AdminCreateCustomerPageProps {
  existingId?: number;
}

export const AdminCreateCustomerPage: React.FC<AdminCreateCustomerPageProps> = function (
  props
) {
  const history = useHistory();
  const toastContext = React.useContext(ToastContext);
  const validation = useValidation(ValidationRules.Customer);
  const [name, setName] = validation.useState('name', '');
  const [updateCustomer, updateCustomerResult] = useUpdateCustomerMutation({
    onCompleted() {
      toastContext.showToast(
        'クライアント情報を作成しました',
        'success',
        10000
      );
    },
  });
  const [getCustomer, getCustomerResult] = useGetCustomerLazyQuery({
    onCompleted(data) {
      const account = data.getCustomer;
      setName(account.name);
    },
  });

  if (updateCustomerResult.called) {
    if (updateCustomerResult.error) {
      return <GraphQLErrorDialog error={updateCustomerResult.error} />;
    }

    if (updateCustomerResult.loading || !updateCustomerResult.data) {
      return <Loading />;
    }

    history.push('/admin');
  }

  if (props.existingId) {
    if (!getCustomerResult.called) {
      getCustomer({
        variables: {
          customerId: props.existingId,
        },
      });
    }

    if (getCustomerResult.error) {
      return <GraphQLErrorDialog error={getCustomerResult.error} />;
    }

    if (getCustomerResult.loading || !getCustomerResult.data) {
      return <Loading />;
    }
  }

  return (
    <div className="AdminCreateAccountPage-container">
      <h1>アカウント作成</h1>
      <div className="App-form PlaceCreatePage-form">
        <div className="App-input_container">
          <Input
            label="クライアント名"
            className="AdminCreateAccountPage-name_input"
            type="string"
            onChange={(event) => {
              const name = event.target.value;
              setName(name);
            }}
            value={name}
            validationMessage={validation.get('name')}
          />
        </div>
        <div>
          <UpdateButton
            create={!props.existingId}
            disabled={validation.isInvalid}
            className="PlaceCreatePage-create_button"
            onClick={() => {
              updateCustomer({
                variables: {
                  input: {
                    id: props.existingId,
                    name: name,
                  },
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
