import * as React from 'react';
import { useHistory } from 'react-router';
import Cookies from 'universal-cookie';
import { AppContext } from '../AppContext';
import { Button } from '../components/Button';
import { ConfirmDialog } from '../components/Dialogs';
import { formatDate } from '../utils/DateHelper';
import './MyPage.css';

/**
 * マイアカウントページ
 */
export const MyPage: React.FC = function () {
  const appContext = React.useContext(AppContext);
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);

  if (!appContext || !appContext.state.me) {
    throw new Error();
  }

  const me = appContext.state.me;

  return (
    <div className="MyPage-container">
      <ConfirmDialog
        title="確認"
        message={<p>ログアウトしますか？</p>}
        open={openDialog}
        onYes={() => {
          const cookies = new Cookies();
          cookies.remove('auth-token');
          appContext.dispatch({ type: 'removeAccount' });
          history.push('/login');
        }}
        onNo={() => setOpenDialog(false)}
      />
      <h1>
        <span>マイページ</span>
      </h1>
      <div className="MyPage-name">
        <span className="MyPage-name_data">
          <span className="name">{me.name}</span> さん
        </span>
      </div>
      <div className="MyPage-screen_id">
        <label className="MyPage-label">ID</label>
        <span className="MyPage-data">{me.screenId}</span>
      </div>
      <div className="MyPage-department">
        <label className="MyPage-label">所属部署</label>
        <span className="MyPage-data">{me.department}</span>
      </div>
      <div className="MyPage-created">
        <label className="MyPage-label">作成日</label>
        <span className="MyPage-data">{formatDate(me.created)}</span>
      </div>
      <div className="MyPage-change_password_button">
        <Button
          className="App-button"
          onClick={() => history.push('/password/edit')}
        >
          パスワード設定
        </Button>
      </div>
      <div className="MyPage-logout_button">
        <Button className="App-button" onClick={() => setOpenDialog(true)}>
          ログアウト
        </Button>
      </div>
    </div>
  );
};
