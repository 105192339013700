import * as React from 'react';
import './stylesheets/Loading.css';

const LoadingCircleIcon: React.FC = function () {
  return <img className="Loading-circle" alt="Loading" src="/circle.png" />;
};

interface LoadingProps {
  open?: boolean;
}

export const Loading: React.FC<LoadingProps> = function (props) {
  if (props.open !== undefined && !props.open) {
    return <div></div>;
  }
  return (
    <div className="Loading-background">
      <LoadingCircleIcon />
      <LoadingCircleIcon />
      <LoadingCircleIcon />
    </div>
  );
};
