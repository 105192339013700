import * as React from 'react';
import { Validated } from './Validated';

interface IInputProps {
  label?: string;
  type?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validationMessage?: string;
  value?: string;
}

export const Input: React.FC<IInputProps> = function (props) {
  return (
    <div className="App-input_container">
      {(() => {
        if (!props.label) {
          return null;
        }
        return <label className="App-label">{props.label}</label>;
      })()}
      <Validated message={props.validationMessage}>
        <input
          className={props.className}
          type={props.type}
          onChange={props.onChange}
          value={props.value}
        />
      </Validated>
    </div>
  );
};

interface ICheckboxProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  return (
    <input
      type="checkbox"
      checked={props.value}
      onChange={(event) => props.onChange?.(event.target.checked)}
    />
  );
};

interface ISelectorProps {
  label?: string;
}

export const Selector: React.FC<ISelectorProps> = function (props) {
  return (
    <div className="App-input_container">
      <select>{props.children}</select>
    </div>
  );
};

interface ILabelProps {
  label: string;
}

export const Label: React.FC<ILabelProps> = function (props) {
  return <label className="App-label">{props.label}</label>;
};

interface IDeletableInputProps extends IInputProps {
  onDelete?: () => void;
}

export const DeletableInput: React.FC<IDeletableInputProps> = function (props) {
  return (
    <div className="App-input_container">
      {(() => {
        if (props.label) {
          return;
        }
      })()}
      <input
        className={props.className}
        type={props.type}
        onChange={props.onChange}
        value={props.value}
      />
      <button onClick={() => props.onDelete?.()}>削除</button>
    </div>
  );
};
