import dateformat from 'dateformat';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  useDeletePlaceFaceMarkerMutation,
  useGetPlaceFaceMarkersLazyQuery,
} from '../generated/graphql';
import { Loading } from '../Loading';
import { Button } from '../components/Button';
import {
  ConfirmDialog,
  GraphQLMutationErrorDialog,
} from '../components/Dialogs';
import './PlaceDxfPicturesPage.css';

type PlaceDxfMarkersPage = RouteComponentProps<{
  placeId: string;
  placeFaceId: string;
}>;

interface Marker {
  object_id: number;
  id: number;
  user_id: number;
  code_id: number;
  sort_id: number;
  work_id: number;
  step_id: number;
}

export const PlaceDxfMarkersPage: React.FC<PlaceDxfMarkersPage> = function (
  props
) {
  const placeId = parseInt(props.match.params.placeId);
  const placeFaceId = parseInt(props.match.params.placeFaceId);

  const [deleteTarget, setDeleteTarget] = React.useState<Marker>();
  const [deleteMarker, deleteMarkerResult] = useDeletePlaceFaceMarkerMutation({
    onCompleted() {
      getMarkers();
    },
  });
  const [getMarkers, getMarkersResult] = useGetPlaceFaceMarkersLazyQuery({
    variables: { placeId, placeFaceId },
  });

  if (!getMarkersResult.called) {
    getMarkers();
  }

  if (
    getMarkersResult.loading ||
    !getMarkersResult.data ||
    deleteMarkerResult.loading
  ) {
    return <Loading />;
  }

  if (deleteMarkerResult.error) {
    return <GraphQLMutationErrorDialog error={deleteMarkerResult.error} />;
  }

  const accounts = getMarkersResult.data.getAccounts;
  const codes = getMarkersResult.data.getPlace.codes;
  const sorts = getMarkersResult.data.getPlace.sorts;
  const works = getMarkersResult.data.getPlace.works;
  const steps = works.flatMap(w => [w.default_step, ...w.steps]);

  function getData(marker: Marker) {
    const account = accounts.find((a) => a.id === marker.user_id);
    const code = codes.find((c) => c.id === marker.code_id);
    const sort = sorts.find((s) => s.id === marker.sort_id);
    const work = works.find((w) => w.work_id === marker.work_id) ?? null;
    const step = steps.find(s => s.step_id === marker.step_id) ?? null;

    return [account, code, sort, work, step];
  }

  return (
    <div className="PlaceDxfListPage-container">
      {(function () {
        if (!deleteTarget) {
          return;
        }

        return (
          <ConfirmDialog
            open={true}
            title="マーカー削除"
            message={
              <p>
                {(function () {
                  const [account, code, sort] = getData(deleteTarget);
                  return `マーカー 作成者: ${
                    account?.name ?? 'なし'
                  } 補修コード: ${code?.name ?? 'なし'} 補修分類: ${
                    sort?.name ?? 'なし'
                  } を削除しますか？`;
                })()}
              </p>
            }
            onYes={() => {
              deleteMarker({
                variables: { markerId: deleteTarget.object_id },
              });
              setDeleteTarget(undefined);
            }}
            onNo={() => setDeleteTarget(undefined)}
          />
        );
      })()}
      <table className="App-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>補修分類</th>
            <th>補修コード</th>
            <th>作成者</th>
            <th>工種</th>
            <th>工程</th>
            <th>履歴番号</th>
            <th>日付</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {getMarkersResult.data.getPlaceFace.markers.map((marker) => {
            const [account, code, sort, work, step] = getData(marker);

            return (
              <tr>
                <td>{marker.id}</td>
                <td>{sort?.name}</td>
                <td>{code?.name}</td>
                <td>{account?.name}</td>
                <td>{work?.name}</td>
                <td>{step?.name}</td>
                <td>{marker.revision}</td>
                <td>{dateformat(marker.created, 'yyyy年m月d日 HH:MM:ss')}</td>
                <td>
                  <Button
                    color="red"
                    mini={true}
                    onClick={() => setDeleteTarget(marker)}
                  >
                    削除
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
