import * as React from 'react';
import master, { ShapeTypes, InputField } from '../../Master';
import { NotifyDialog } from '../Dialogs';
import { FieldType, IDrawingObject } from './DrawingObjectsInput';

interface ShapeTile {
  readonly id: number;
  readonly name: string;
  readonly width: number;
  readonly height: number;
  readonly margin: number;
}

interface PlaceCodeLayersInputProps {
  tiles: ShapeTile[];
  drawingObjects: IDrawingObject[];

  shapeSchemeId: number;
  shapeSymbolSchemeId: number;
  onChangeShape: (id: number) => void;
  onChangeShapeSymbol: (id: number) => void;
}

export const PlaceCodeShapeInput: React.FC<PlaceCodeLayersInputProps> = function (
  props
) {
  const [canceled, setCanceled] = React.useState(false);

  return (
    <div>
      {(function () {
        if (!canceled) {
          return;
        }

        return (
          <NotifyDialog
            open={true}
            message={
              <p>
                作画文字列に参照されている項目があるため図形の種類を変更できません。
              </p>
            }
            onYes={() => {
              setCanceled(false);
            }}
          />
        );
      })()}
      <div className="App-input_container">
        <label className="App-label">図形の種類</label>
        <select
          className="PlaceCreateCodePage-shape_select"
          value={props.shapeSchemeId}
          onChange={(event) => {
            const shapeId = parseInt(event.target.value);
            const shape = master.Shapes.find((s) => s.id === shapeId);
            if (!shape) {
              throw new Error('shape was not found');
            }
            const inputFields = shape.input_field_ids
              .map((id) => master.InputFields.find((field) => field.id === id))
              .filter((field): field is InputField => !!field);

            // DrawingObject
            const changable = props.drawingObjects.every((obj) => {
              if (obj.fieldType !== FieldType.InputField) {
                return true;
              }

              if (inputFields.find((field) => field.id === obj.fieldIndex)) {
                return true;
              }

              return false;
            });

            if (!changable) {
              setCanceled(true);
              return;
            }

            props.onChangeShape(shapeId);

            // シンボルの初期値
            switch (shapeId) {
              case ShapeTypes.Symbol:
                props.onChangeShapeSymbol(master.ShapeSymbols[0].id);
                break;
              default:
                props.onChangeShapeSymbol(0);
                break;
            }
          }}
        >
          {master.Shapes.map((shape) => (
            <option key={shape.id} value={shape.id}>{shape.name}</option>
          ))}
        </select>
      </div>
      <div>
        {(function () {
          switch (props.shapeSchemeId) {
            case ShapeTypes.Symbol:
              return (
                <div className="App-input_container">
                  <label className="App-label">シンボルの種類</label>
                  <select
                    className="PlaceCreateCodePage-shape_symbol_select"
                    value={props.shapeSymbolSchemeId}
                    onChange={(event) =>
                      props.onChangeShapeSymbol(parseInt(event.target.value))
                    }
                  >
                    {master.ShapeSymbols.map((shapeSymbol) => (
                      <option value={shapeSymbol.id}>{shapeSymbol.name}</option>
                    ))}
                  </select>
                </div>
              );
          }
        })()}
      </div>
    </div>
  );
};
