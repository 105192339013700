import * as React from 'react';
import { useHistory } from 'react-router';
import {
  useUpdateAccountMutation,
  useUpdateAccountPasswordMutation,
  useGetAccountLazyQuery,
} from '../generated/graphql';
import { Loading } from '../Loading';
import { GraphQLErrorDialog } from '../components/Dialogs';
import { ToastContext } from '../components/Toast';
import { PasswordInput } from '../components/PasswordInput';
import './AdminCreateAccountPage.css';
import { Button, UpdateButton } from '../components/Button';
import { useValidation } from '../utils/Validation';
import { ValidationRules } from '../utils/ValidationRules';
import { Input } from '../components/Input';

interface AdminCreateAccountPageProps {
  existingId?: number;
}

export const AdminCreateAccountPage: React.FC<AdminCreateAccountPageProps> = function (
  props
) {
  let rules;
  if (!props.existingId) {
    rules = ValidationRules.Account;
  } else {
    rules = {
      name: ValidationRules.Account.name,
      screenId: ValidationRules.Account.screenId,
      department: ValidationRules.Account.department,
    };
  }

  const history = useHistory();
  const toastContext = React.useContext(ToastContext);
  const validation = useValidation(rules);
  const [name, setName] = validation.useState('name', '');
  const [screenId, setScreenId] = validation.useState('screenId', '');
  const [department, setDepartment] = validation.useState('department', '');
  const [authLv, setAuthLv] = React.useState(1);
  const [password, setPassword] = React.useState<string | undefined>(
    'password'
  );
  const [
    updateAccountPassword,
    updateAccountPasswordResult,
  ] = useUpdateAccountPasswordMutation({
    onCompleted() {
      toastContext.showToast('アカウントを作成しました', 'success', 10000);
    },
  });
  const [updateAccount, updateAccountResult] = useUpdateAccountMutation({
    onCompleted() {
      if (!password) {
        toastContext.showToast('アカウントを作成しました', 'success', 10000);
      }
    },
  });
  const [getAccount, getAccountResult] = useGetAccountLazyQuery({
    onCompleted(data) {
      const account = data.getAccount;
      setScreenId(account.screen_id);
      setName(account.name);
      setDepartment(account.department);
      setAuthLv(account.authority.level);
    },
  });

  if (updateAccountResult.called) {
    if (updateAccountResult.error) {
      return <GraphQLErrorDialog error={updateAccountResult.error} />;
    }

    if (updateAccountResult.loading || !updateAccountResult.data) {
      return <Loading />;
    }

    if (password) {
      if (!updateAccountPasswordResult.called) {
        const account = updateAccountResult.data.updateAccount;
        updateAccountPassword({
          variables: {
            input: {
              account_id: account.id,
              password: password,
            },
          },
        });
      }

      if (updateAccountPasswordResult.error) {
        return <GraphQLErrorDialog error={updateAccountPasswordResult.error} />;
      }

      if (updateAccountPasswordResult.loading) {
        return <Loading />;
      }
    }

    history.push('/admin');
  }

  if (props.existingId) {
    if (!getAccountResult.called) {
      getAccount({
        variables: {
          accountId: props.existingId,
        },
      });
    }

    if (getAccountResult.loading || !getAccountResult.data) {
      return <Loading />;
    }
  }

  return (
    <div className="AdminCreateAccountPage-container">
      <h1>アカウント作成</h1>
      {(function () {
        if (!props.existingId) {
          return;
        }

        return (
          <div>
            <Button
              onClick={() =>
                history.push(
                  `/admin/accounts/${props.existingId}/password/edit`
                )
              }
            >
              パスワード設定
            </Button>
          </div>
        );
      })()}
      <div className="App-form PlaceCreatePage-form">
        <div className="App-input_container">
          <Input
            label="ID"
            className="AdminCreateAccountPage-screen_id_input"
            type="string"
            onChange={(event) => {
              const screenId = event.target.value;
              setScreenId(screenId);
            }}
            value={screenId}
            validationMessage={validation.get('screenId')}
          />
        </div>
        <div className="App-input_container">
          <Input
            label="名前"
            className="AdminCreateAccountPage-name_input"
            type="string"
            onChange={(event) => {
              const name = event.target.value;
              setName(name);
            }}
            value={name}
            validationMessage={validation.get('name')}
          />
        </div>
        <div className="App-input_container">
          <Input
            label="所属部署"
            className="AdminCreateAccountPage-department_input"
            type="string"
            onChange={(event) => {
              const department = event.target.value;
              setDepartment(department);
            }}
            value={department}
            validationMessage={validation.get('department')}
          />
        </div>
        <div className="App-input_container">
          <label className="App-label">権限</label>
          <select
            onChange={(event) => setAuthLv(parseInt(event.target.value))}
            value={authLv}
          >
            <option value={1}>作業者</option>
            <option value={2}>マスター編集者</option>
            <option value={3}>管理者</option>
          </select>
        </div>
        {(function () {
          if (!props.existingId) {
            return (
              <PasswordInput
                validation={validation}
                onChange={(password, check) => {
                  if (check) {
                    setPassword(password);
                  } else {
                    setPassword(undefined);
                  }
                }}
              />
            );
          }
        })()}
        <div>
          <UpdateButton
            create={!props.existingId}
            disabled={validation.isInvalid || (!!props.existingId && !password)}
            className="App-button PlaceCreatePage-create_button"
            onClick={() => {
              updateAccount({
                variables: {
                  input: {
                    id: props.existingId,
                    screen_id: screenId,
                    name: name,
                    department: department,
                    auth_lv: authLv,
                  },
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
