import * as React from 'react';
import { AppContext } from '../AppContext';

interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: ButtonColors;
  mini?: boolean;
}

type ButtonColors = 'normal' | 'red' | 'white';

export const Button: React.FC<IButtonProps> = function ({ color, mini, ...buttonHTMLProps }) {
  color = color ?? 'normal';
  let colorClassName: string;
  switch (color) {
    case 'red':
      colorClassName = ' App-button_color_red';
      break;
    case 'white':
      colorClassName = ' App-button_color_gray';
      break;
    case 'normal':
    default:
      colorClassName = ' App-button_color_green';
      break;
  }

  let miniClassName = '';
  if (mini) {
    miniClassName = ' App-button_mini';
  }

  let className = 'App-button';
  if (buttonHTMLProps.className) {
    className += ` ${buttonHTMLProps.className}`;
  }

  return (
    <button {...buttonHTMLProps} className={className + colorClassName + miniClassName}>
      {buttonHTMLProps.children}
    </button>
  );
};

interface UpdateButtonProps extends IButtonProps {
  create: boolean;
}

export const UpdateButton: React.FC<UpdateButtonProps> = function ({ create, ...buttonHTMLProps }) {
  const message = create ? '作成' : '変更';
  return <Button {...buttonHTMLProps}>{message}</Button>;
};

interface IAuthButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: ButtonColors;
  mini?: boolean;
  authLv: number;
}

export const AuthButton: React.FC<IAuthButtonProps> = function ({ authLv, ...props }) {
  const appContext = React.useContext(AppContext);
  const me = appContext.state.me;

  if (!me) {
    return <div></div>;
  }

  if (me.authority.level < authLv) {
    return <div></div>;
  }

  return <Button {...props}>{props.children}</Button>;
};
