import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  useGetPlaceFaceLazyQuery,
  useUpdatePlaceFaceMutation,
} from '../generated/graphql';
import { Loading } from '../Loading';
import {
  GraphQLErrorDialog,
  GraphQLMutationErrorDialog,
} from '../components/Dialogs';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import * as Const from '../Const';
import './PlaceDxfUploadPage.css';
import { ApiClient } from '../utils/ApiClient';

type IPlaceDxfUploadPageProps = RouteComponentProps<{ placeId: string }> & {
  existingId?: number;
};

export const PlaceDxfUploadPage: React.FC<IPlaceDxfUploadPageProps> = function (
  props
) {
  const placeId = parseInt(props.match.params.placeId);
  const history = useHistory();
  const [uploading, startUpload] = React.useState(false);
  const [dxfFile, setDxfFile] = React.useState<File | null>(null);
  const [images, setImages] = React.useState<(File | null)[]>([]);
  const [selectedUploadedImages, selectUploadedImages] = React.useState<
    boolean[]
  >([]);

  const [getDxf, getDxfResult] = useGetPlaceFaceLazyQuery({
    onCompleted(data) {
      selectUploadedImages(data.getPlaceFace.dxf_images.map(() => true));
    },
  });
  const [updateFace, updateFaceResult] = useUpdatePlaceFaceMutation();

  if (props.existingId) {
    if (!getDxfResult.called) {
      getDxf({ variables: { placeFaceId: props.existingId } });
    }

    if (getDxfResult.error) {
      return <GraphQLErrorDialog error={getDxfResult.error} />;
    }

    if (getDxfResult.loading || !getDxfResult.data) {
      return <Loading />;
    }
  }

  if (updateFaceResult.called) {
    if (updateFaceResult.error) {
      return <GraphQLMutationErrorDialog error={updateFaceResult.error} />;
    }

    if (updateFaceResult.loading) {
      return <Loading />;
    }

    history.push(`/places/${placeId}/faces`);
  }

  const pictureIndices = [];
  for (let i = 0; i < images.length; i++) {
    pictureIndices[i] = i;
  }

  return (
    <div className="PlaceDxfUploadPage-container">
      <Loading open={uploading} />
      <p>1. 元の図面ファイルを選択 (32MBまで)</p>
      <div>
        {(function () {
          if (getDxfResult.data) {
            return (
              <div>
                <h4>現在のデータ</h4>
                <p>{getDxfResult.data.getPlaceFace.dxf_file_name}</p>
              </div>
            );
          }
        })()}
        <Input
          type="file"
          onChange={(event) => {
            const files = event.target.files;
            if (!files || files.length === 0) {
              setDxfFile(null);
              return;
            }

            // TODO: ファイルが複数指定されているときの処理

            const file = files[0];
            setDxfFile(file);
          }}
        />
      </div>
      <div>
        <p>2. 図面ファイルに関連する画像がある場合は追加 (任意、32MBまで)</p>
        <table className="App-table">
          <thead>
            <th colSpan={2}>データ</th>
            <th>操作</th>
          </thead>
          <tbody>
            {(function () {
              if (getDxfResult.data) {
                const dxfImages = getDxfResult.data.getPlaceFace.dxf_images;
                return dxfImages.map((image, index) => {
                  if (!selectedUploadedImages[index]) {
                    return <tr></tr>;
                  }
                  return (
                    <tr>
                      <td>{image.file_name}</td>
                      <td>
                        <a href={`${Const.API_URL}${image.file_url}`}>
                          ダウンロード
                        </a>
                      </td>
                      <td>
                        <Button
                          mini={true}
                          color="red"
                          onClick={() => {
                            const next = selectedUploadedImages.slice();
                            next[index] = false;
                            selectUploadedImages(next);
                          }}
                        >
                          削除
                        </Button>
                      </td>
                    </tr>
                  );
                });
              }
            })()}
            {pictureIndices.map((index) => {
              return (
                <tr key={`picture-input-${index}`}>
                  <td colSpan={2}>
                    <Input
                      type="file"
                      onChange={(event) => {
                        const next = images.slice();
                        if (
                          !event.target.files ||
                          event.target.files.length === 0
                        ) {
                          next[index] = null;
                        } else {
                          next[index] = event.target.files[0];
                        }

                        setImages(next);
                      }}
                    />
                  </td>
                  <td>
                    <Button
                      color="red"
                      mini={true}
                      onClick={() =>
                        setImages(images.filter((_, i) => i !== index))
                      }
                    >
                      削除
                    </Button>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={3}>
                <Button onClick={() => setImages((prev) => [...prev, null])}>
                  写真の追加
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button
        className="App-button PlaceDxfUploadPage-upload_button"
        onClick={async () => {
          if (uploading) {
            return;
          }

          startUpload(true);

          const apiClient = new ApiClient();

          let dxf;
          if (dxfFile) {
            // アップロードするファイルの中身を読み込む
            const [dxfFileUrl, dxfFileSize] = await apiClient.upload(dxfFile);

            dxf = {
              file_name: dxfFile.name,
              file_url: dxfFileUrl,
              file_size: dxfFileSize,
            };
          }

          const dxfImages = await Promise.all(
            images
              .filter((_): _ is File => _ != null)
              .map(async (imageFile) => {
                const [fileUrl] = await apiClient.upload(imageFile);
                return {
                  file_name: imageFile.name,
                  file_url: fileUrl,
                };
              })
          );

          startUpload(false);

          let uploadedDxfImages
          let nextRevision;
          if (getDxfResult.data) {
            uploadedDxfImages = getDxfResult.data.getPlaceFace.dxf_images
              .map((image) => image.id)
              .filter((_, i) => selectedUploadedImages[i]);
            nextRevision = getDxfResult.data.getPlaceFace.revision + 1;
          }

          updateFace({
            variables: {
              input: {
                id: props.existingId,
                revision: nextRevision,
                place_id: placeId,
                dxf,
                dxf_images: dxfImages,
                uploaded_dxf_images: uploadedDxfImages ?? [],
              },
            },
          });
        }}
      >
        {props.existingId ? '変更' : 'アップロード'}
      </Button>
    </div>
  );
};
