import * as React from 'react';
import master from '../../Master';
import { Input } from '../Input';
import { Button } from '../Button';
import { IInputField } from './InputFieldsInput';
import { IAdditionalField } from './AdditoinalFieldsInput';
import { IOutputField } from './OutputFieldsInput';
import { Validated } from '../Validated';
import { LineSelect, ArrowEndSelect } from '../ImageSelect';

import './DrawingObjectInput.css';

export interface IDrawingObject {
  id?: number;
  fieldType: FieldType;
  fieldIndex: number;
  prefix: string;
  suffix: string;
  digit: number;
  lineType: number;
  arrowEndType: number;
}

export enum FieldType {
  InputField = 1,
  AdditionalField,
  OutputField,
}

interface DrawingObjectsInputProps {
  readonly value: IDrawingObject[];
  readonly inputFields: IInputField[];
  readonly additionalFields: IAdditionalField[];
  readonly outputFields: IOutputField[];
  readonly validation: string | undefined;
  onChange: (objects: IDrawingObject[]) => void;
}

export const DrawingObjectsInput: React.FC<DrawingObjectsInputProps> = function (
  props
) {
  function changeValue(index: number, obj: IDrawingObject) {
    const next = props.value.slice();
    next[index] = obj;
    props.onChange(next);
  }
  function addValue() {
    props.onChange([...props.value, defaultDrawingObject()]);
  }
  function removeValue(index: number) {
    props.onChange(props.value.filter((_, i) => i !== -index));
  }

  function defaultDrawingObject(): IDrawingObject {
    return {
      fieldType: FieldType.InputField,
      fieldIndex:
        props.inputFields.length === 0 ? 0 : props.inputFields[0].master.id,
      prefix: '',
      suffix: '',
      lineType: 0,
      arrowEndType: 0,
      digit: 2,
    };
  }

  function row(obj: IDrawingObject, index: number) {
    return (
      <tr key={index}>
        <td>
          <select
            value={obj.fieldType}
            onChange={(event) => {
              let fieldType: FieldType;
              let initialFieldIndex: number;
              switch (event.target.value) {
                case '1':
                  fieldType = FieldType.InputField;
                  initialFieldIndex = master.InputFields[0].id;
                  break;
                case '2':
                  fieldType = FieldType.AdditionalField;
                  initialFieldIndex = 0;
                  break;
                case '3':
                  fieldType = FieldType.OutputField;
                  initialFieldIndex = 0;
                  break;
                default:
                  throw new Error('invalid type');
              }

              changeValue(index, {
                ...obj,
                fieldType,
                fieldIndex: initialFieldIndex,
              });
            }}
          >
            <option value={FieldType.InputField}>基本入力項目</option>
            <option value={FieldType.AdditionalField}>追加入力項目</option>
            <option value={FieldType.OutputField}>追加出力項目</option>
          </select>
        </td>
        <td>
          {(() => {
            switch (obj.fieldType) {
              case FieldType.InputField:
                if (props.inputFields.length === 0) {
                  return <p>項目がありません</p>;
                }
                break;
              case FieldType.AdditionalField:
                if (props.additionalFields.length === 0) {
                  return <p>項目がありません</p>;
                }
                break;
              case FieldType.OutputField:
                if (props.outputFields.length === 0) {
                  return <p>項目がありません</p>;
                }
                break;
            }

            return (
              <select
                value={obj.fieldIndex}
                onChange={(event) =>
                  changeValue(index, {
                    ...obj,
                    fieldIndex: parseInt(event.target.value),
                  })
                }
              >
                {(function () {
                  switch (obj.fieldType) {
                    case FieldType.InputField:
                      return props.inputFields.map((f) => (
                        <option key={`input_field-${f.master.id}`} value={f.master.id}>{f.master.name}</option>
                      ));
                    case FieldType.AdditionalField:
                      return props.additionalFields.map((f, i) => (
                        <option key={`additional_field-${i}`} value={i}>{f.name}</option>
                      ));
                    case FieldType.OutputField:
                      return props.outputFields.map((f, i) => (
                        <option key={`output_field-${i}`} value={i}>{f.name}</option>
                      ));
                  }
                })()}
              </select>
            );
          })()}
        </td>
        <td>
          <Input
            className="DrawingObjectInput-text_input"
            value={obj.prefix}
            onChange={(event) =>
              changeValue(index, { ...obj, prefix: event.target.value })
            }
          />
        </td>
        <td>
          <Input
            className="DrawingObjectInput-text_input"
            value={obj.suffix}
            onChange={(event) =>
              changeValue(index, { ...obj, suffix: event.target.value })
            }
          />
        </td>
        <td>
          <Input
            className="DrawingObjectInput-number_input"
            value={obj.digit.toString()}
            type="number"
            onChange={(event) =>
              changeValue(index, {
                ...obj,
                digit: parseInt(event.target.value),
              })
            }
          />
        </td>
        <td>
          <LineSelect
            value={obj.lineType}
            onChange={(value) =>
              changeValue(index, { ...obj, lineType: value })
            }
            optionClassName="DrawingObjectInput-image_option"
          />
        </td>
        <td>
          <ArrowEndSelect
            value={obj.arrowEndType}
            onChange={(value) =>
              changeValue(index, { ...obj, arrowEndType: value })
            }
            optionClassName="DrawingObjectInput-image_option"
          />
        </td>
        <td className="App-table_button_cell">
          <Button mini={true} color="red" onClick={() => removeValue(index)}>
            削除
          </Button>
        </td>
      </tr>
    );
  }

  return (
    <Validated message={props.validation}>
      <table className="App-table">
        <thead>
          <tr>
            <th colSpan={2}>項目名</th>
            <th>前置文字列</th>
            <th>後置文字列</th>
            <th>表示桁数</th>
            <th>線種</th>
            <th>矢印</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.value.map(row)}
          <tr>
            <td colSpan={8}>
              <Button onClick={() => addValue()}>追加</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Validated>
  );
};
