import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Auth } from './Auth';
import { Header } from './Header';
import { IndexPage } from './pages/IndexPage';
import { LoginPage } from './pages/LoginPage';
import { BrowserRouter } from 'react-router-dom';
import { AppContext, appContextReducer } from './AppContext';
import { ApiErrorDialog } from './components/Dialogs';
import { ApiClient } from './utils/ApiClient';
import { Loading } from './Loading';
import { SuperAdminPage } from './pages/SuperAdminPage';

/**
 * ルーティングを行います。
 * このページでメインのコンテンツを切り替えるので、実質的なメインの要素になります。
 */
export const Routing: React.FC = function () {
  const [state, dispatch] = React.useReducer(appContextReducer, {});
  const [called, setCalled] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  function MainContents() {
    return (
      <>
        <Header />
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Auth>
            <Route path="/" component={() => <IndexPage />} />
          </Auth>
        </Switch>
      </>
    );
  }

  if (!state.appInfo) {
    if (!called) {
      const apiClient = new ApiClient();
      apiClient
        .getAppinfo()
        .then((appInfo) => dispatch({ type: 'setAppInfo', appInfo }))
        .catch(setError);

      setCalled(true);
    }

    if (error) {
      return (
        <BrowserRouter>
          <ApiErrorDialog error={error} />
          <MainContents />
        </BrowserRouter>
      );
    }

    return (
      <BrowserRouter>
        <Loading />
        <MainContents />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{ ready: true, state, dispatch }}>
        <MainContents />
      </AppContext.Provider>
    </BrowserRouter>
  );
};
