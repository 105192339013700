import * as React from 'react';
import './AuthorityLabel.css';

interface IAuthorityLabelProps {
  authority: Authority;
}

export enum Authority {
  Normal = 1,
  MasterAdmin = 2,
  Admin = 3,
}

export const AuthorityLabel: React.FC<IAuthorityLabelProps> = function (props) {
  switch (props.authority) {
    case Authority.Admin:
      return <span className="AuthorityLabel-admin">管理者</span>;
    case Authority.MasterAdmin:
      return (
        <span className="AuthorityLabel-master_admin">マスター編集者</span>
      );
    case Authority.Normal:
      return <span></span>;
  }
};
