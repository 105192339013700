interface ShapeSymbol {
  readonly id: number;
  readonly name: string;
}

interface Shape {
  readonly id: number;
  readonly name: string;
  readonly input_field_ids: number[];
}

interface Frame {
  readonly id: number;
  readonly name: string;
}

export interface InputField {
  readonly id: number;
  readonly name: string;
  readonly defaultUnit: string;
}

export enum ShapeTypes {
  Segment = 1,
  Polygon = 2,
  Tile = 3,
  Symbol = 4,
  JointlessTile = 8,
}

interface Master {
  readonly Shapes: Shape[];
  readonly InputFields: InputField[];
  readonly ShapeSymbols: ShapeSymbol[];

  readonly Frames: Frame[];
}

const master: Master = {
  Shapes: [
    {
      id: ShapeTypes.Segment,
      name: '線分',
      input_field_ids: [9, 8, 7],
    },
    {
      id: ShapeTypes.Polygon,
      name: '多角形',
      input_field_ids: [9, 8, 1, 2, 5],
    },
    {
      id: ShapeTypes.Tile,
      name: 'タイル',
      input_field_ids: [9, 8, 1, 2, 3, 4, 5, 6],
    },
    {
      id: ShapeTypes.Symbol,
      name: 'シンボル',
      input_field_ids: [9, 8],
    },
    {
      id: ShapeTypes.JointlessTile,
      name: 'タイル・割付無し',
      input_field_ids: [9, 8, 1, 2, 5, 3, 4, 6],
    },
  ],
  InputFields: [
    {
      id: 1,
      name: '横の長さ',
      defaultUnit: 'mm',
    },
    {
      id: 2,
      name: '縦の長さ',
      defaultUnit: 'mm',
    },
    {
      id: 3,
      name: 'タイルの枚数(縦)',
      defaultUnit: '枚',
    },
    {
      id: 4,
      name: 'タイルの枚数(横)',
      defaultUnit: '枚',
    },
    {
      id: 5,
      name: '面積',
      defaultUnit: '㎡',
    },
    {
      id: 6,
      name: 'タイル総枚数',
      defaultUnit: '枚',
    },
    {
      id: 7,
      name: '長さ',
      defaultUnit: 'mm',
    },
    {
      id: 8,
      name: '総数',
      defaultUnit: '個',
    },
    {
      id: 9,
      name: '連番',
      defaultUnit: '番',
    },
  ],
  ShapeSymbols: [
    {
      id: 1,
      name: '十字',
    },
    {
      id: 2,
      name: 'クロス',
    },
    {
      id: 3,
      name: '四角形',
    },
  ],
  Frames: [
    {
      id: 1,
      name: '縦A5',
    },
    {
      id: 2,
      name: '縦A4',
    },
    {
      id: 3,
      name: '縦A3',
    },
    {
      id: 4,
      name: '縦A2',
    },
    {
      id: 5,
      name: '縦A1',
    },
    {
      id: 6,
      name: '縦B5',
    },
    {
      id: 7,
      name: '縦B4',
    },
    {
      id: 8,
      name: '縦B3',
    },
    {
      id: 9,
      name: '縦B2',
    },
    {
      id: 10,
      name: '縦B1',
    },
    {
      id: 11,
      name: '横A5',
    },
    {
      id: 12,
      name: '横A4',
    },
    {
      id: 13,
      name: '横A3',
    },
    {
      id: 14,
      name: '横A2',
    },
    {
      id: 15,
      name: '横A1',
    },
    {
      id: 16,
      name: '横B5',
    },
    {
      id: 17,
      name: '横B4',
    },
    {
      id: 18,
      name: '横B3',
    },
    {
      id: 19,
      name: '横B2',
    },
    {
      id: 20,
      name: '横B1',
    },
  ],
};

export default master;
